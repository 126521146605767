import { mp } from '..';
import { API_BASE_URL } from '../constants/api';
import { Action, Dispatch, User } from '../models';
import { RootState } from '../reducers';

/*
  Actions and Action creators
*/

export const SAVE_USER = 'SAVE_USER';
export interface SaveUserPayload {
  user: undefined | User;
}
export const saveUserAction = (payload: SaveUserPayload) => {
  return {
    type: SAVE_USER,
    payload,
  } as Action;
};

export const getMe = () => {
  return (dispatch: Dispatch['function'], getState: () => RootState) => {
    fetch(`${API_BASE_URL}/users/me`, {
      method: 'GET',
      credentials: 'include',
      headers: {},
    })
      .then((r) => r.json())
      .then((resp) => {
        if (resp.email) {
          mp.identify(resp.email);
        }
        dispatch(saveUserAction({ user: resp }));
      });
  };
};
