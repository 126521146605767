import { Client } from 'typesense';
import {
  SearchParams,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';
import { Alt, CommentTs } from '../models';
import { Expression } from 'typescript';

let typesenseClient: null | Client = null;
export const getTypesenseClient = () => {
  if (typesenseClient) return typesenseClient;
  typesenseClient = new Client({
    connectionTimeoutSeconds: 15,
    numRetries: 5,
    nodes: [
      {
        host: '9z5ev304bptk6n1cp-1.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
    apiKey: 'VcNwXZpcYMNt76SBBWuq4gbVEUbPOjBr',
  });
  return typesenseClient;
};

export const loadAllCommentsTypesense = (
  client: Client,
  searchParams: SearchParams
): Promise<SearchResponseHit<CommentTs>[]> => {
  let results: SearchResponseHit<CommentTs>[] = [];
  return new Promise(async (resolve) => {
    for (let i = 1; i < 100; i += 1) {
      const res = await client
        .collections('comments')
        .documents()
        .search({
          ...searchParams,
          page: i,
          per_page: 250,
        });
      results = results.concat(res.hits as SearchResponseHit<CommentTs>[]);
      if (res.hits && res.hits.length !== 250) {
        resolve(results);
        break;
      } else if (res.grouped_hits && res.grouped_hits.length !== 250) {
        throw new Error('loadAllTypesense is not for grouped_hits');
      }
    }
    resolve([]);
  });
};

export const highlightWords2 = (
  str: string,
  words: [Expression, Alt, number, number, string][]
): string => {
  let s = str;
  let offset = 0;

  words.forEach((w) => {
    const a = s.slice(0, w[2] + offset);
    const inBetween = s.slice(w[2] + offset, w[3] + offset);
    const b = s.slice(w[3] + offset);
    const toInsert = `<span class="match" style="padding:2px 4px; border-radius:4px;background: ${w[4]}">${inBetween}</span>`;
    offset += toInsert.length - inBetween.length;
    s = a + toInsert + b;
  });
  return s;
};

export const formatTranscriptHtml = (
  html: string,
  offset: number,
  shortCode: string
) => {
  const paragraphs = html.split('\n\n');
  let s = '';
  paragraphs.forEach((p) => {
    p.split('\n').forEach((sentence) => {
      const timestampStart = parseFloat(sentence.slice(1).split(':')[0]);
      const t = Math.floor(offset + timestampStart);
      s += `<a target="_blank" href="https://www.youtube.com/watch?v=${shortCode}&t=${t}" class="sentence" goto="${t}">${sentence.slice(
        28
      )} </a>`;
    });
    s += `\n\n`;
  });
  return s;
};

export const displayBigNumber = (n: number) => {
  if (n === null) {
    return '0';
  }
  if (n > 1000000000) {
    return `${Math.round((100 * n) / 1000000000) / 100}B`;
  }
  if (n > 1000000) {
    return `${Math.round((100 * n) / 1000000) / 100}M`;
  }
  if (n > 1000) {
    return `${Math.round((100 * n) / 1000) / 100}k`;
  }
  return `${n}`;
};

export const displayBigTime = (n: number) => {
  if (n === null) {
    return '0';
  }
  if (n < 60) {
    return `${n} seconds`;
  }
  if (n < 60 * 60) {
    return `${Math.round(n / 60)} minutes`;
  }
  if (n < 60 * 60 * 24) {
    return `${Math.round(n / (60 * 60))} hours`;
  }
  if (n < 60 * 60 * 24 * 100) {
    return `${Math.round(n / (60 * 60 * 24))} days`;
  }
  return `${n}`;
};

export function debounce(fn: any, delay: number) {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export const calculatePerHour = (perHour: [string, number][]): number => {
  if (perHour.length === 0 || perHour.length === 1) {
    throw new Error('cannot calculate');
  }
  const last = perHour[perHour.length - 1];
  const minus1 = perHour[perHour.length - 2];
  const minus2 = perHour.length > 2 ? perHour[perHour.length - 3] : undefined;
  const interval1 = last[1] - minus1[1];
  if (minus2) {
    const interval2 = minus1[1] - minus2[1];
    const ms = new Date(last[0]).getTime() - new Date(minus2[0]).getTime();
    const hours = ms / 1000 / 60 / 60;
    return Math.round((interval1 + interval2) / hours);
  }
  const ms = new Date(last[0]).getTime() - new Date(minus1[0]).getTime();
  const hours = ms / 1000 / 60 / 60;
  return Math.round(interval1 / hours);
};

const formatDate = (a: Date): string => {
  return new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' })
    .format(a)
    .replace(new Date().getFullYear() + '', '');
};
export const getWeeks = (): [string, string, string][] => {
  const weeks: [string, string, string][] = [];
  const day = 24 * 60 * 60 * 1000;
  const min = 60 * 1000;
  const d = new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  if (d.getDay() === 1) {
    const d1 = d;
    const d2 = new Date(new Date(d).getTime() + 7 * day - 0.2 * min);
    weeks.push([
      d1.toISOString(),
      d2.toISOString(),
      `${formatDate(d1)} - ${formatDate(d2)}`,
    ] as [string, string, string]);
  }

  for (let j = -1; j > -60; j -= 1) {
    const p = new Date(d.getTime() + j * day);
    if (p.getDay() === 1) {
      const d1 = p;
      const d2 = new Date(new Date(p).getTime() + 7 * day - 0.2 * min);
      weeks.push([
        d1.toISOString(),
        d2.toISOString(),
        `${formatDate(d1)} - ${formatDate(d2)}`,
      ] as [string, string, string]);
    }
  }
  return weeks;
};

export const hrefBasedOnShortCode = (
  shortCode: string,
  source: string,
  type: string
) => {
  if (type === 'article') {
    return shortCode;
  }
  if (source === 'youtube') {
    return `https://www.youtube.com/watch?v=${shortCode}`;
  }
  if (source === 'instagram') {
    return `https://www.instagram.com/p/${shortCode}/`;
  }
  return shortCode;
};

export const fullDate = (date: Date) => {
  const fullyear = new Date().getFullYear();
  let dateTime = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
    timeStyle: 'medium',
  })
    .format(date)
    .replace(fullyear + '', '');
  dateTime = dateTime.split(',')[1] + dateTime.split(',')[2];
  return dateTime;
};

export const getValidExprFromUrl = () => {
  if (window.location.href.includes('rolex121367=true')) {
    return ['rolex', 442];
  } else if (window.location.href.includes('omega453423=true')) {
    return ['omega', 443];
  } else if (window.location.href.includes('audemars%20piguet895645=true')) {
    return ['audemars piguet', 448];
  } else if (window.location.href.includes('cannondale998877=true')) {
    return ['cannondale', 170];
  } else if (window.location.href.includes('specialized675691=true')) {
    return ['specialized', 164];
  } else if (window.location.href.includes('giant112286=true')) {
    return ['giant', 165];
  } else if (window.location.href.includes('longines69365=true')) {
    return ['longines', 473];
  } else if (window.location.href.includes('canyon69365=true')) {
    return ['canyon', 178];
  } else if (window.location.href.includes('santa%20cruz895638=true')) {
    return ['santa cruz', 193];
  }
  return null;
}