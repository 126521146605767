import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { getUser } from '../selectors/user';
import { Media, Comment } from '../models';
import { UserState } from '../reducers/user';
import { fullDate, getWeeks } from './utils';
import { LineMyMedia } from './utils/LineMyMedia';
import { CommentSmall } from './utils/CommentSmall';
import { LinkToYoutube } from './utils/LinkToYoutube';
import { Pagination } from './utils/Pagination';

interface MediaAndCommentsProps {
  user: UserState['user'];
  media: Media;
  onBack: () => void;
}

const MediaAndCommentsComponent = (props: MediaAndCommentsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<number[] | undefined>(undefined);
  const [currenPage, setCurrentPage] = useState<number>(1);
  const [comments, setComments] = useState<Comment[]>([]);

  const loadComments = (skip: number) => {
    setLoading(true);
    fetch(
      `${API_BASE_URL}/comments-nested?mediaId=${props.media.id}&skip=${skip}`,
      {
        credentials: 'include',
      }
    )
      .then((r) => r.json())
      .then((res) => {
        setLoading(false);
        setComments(res.comments);
        let pages = [];
        for (let i = 1; i < Math.ceil(res.count / 50); i += 1) {
          pages.push(i);
        }
        setPages(pages);
      })
      .catch((er) => {
        console.error(er);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.user) {
      loadComments(0);
    }
  }, []);

  return (
    <div>
      <button
        className="font-bold text-normal mb-2 underline cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          props.onBack();
        }}
      >
        Back
      </button>
      <LineMyMedia
        key={`media-${props.media.id}`}
        media={props.media}
        onClick={() => {}}
      ></LineMyMedia>
      <div className="p-4 text-lg text-orange-900 bg-orange-100 mb-2">
        {comments.length === 0 && <p>No results</p>}
        {comments.length === 1 && <p>One result</p>}
        {comments.length > 1 && <p>{comments.length} results</p>}
      </div>
      <Pagination
        disabled={loading}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadComments(w * 50);
        }}
      />
      {comments.length > 0 && (
        <div>
          {comments.map((commentLevel0) => {
            return (
              <div className="flex justify-start grid grid-cols-12 pb-8">
                <div className="col-span-6">
                  <CommentSmall
                    key={commentLevel0.id}
                    comment={{
                      ...commentLevel0,
                      indexes: [],
                    }}
                    classNames=""
                  >
                    {props.media.source === 'youtube' ? (
                      <div className="flex text-sm pb-1">
                        <LinkToYoutube
                          text={`${commentLevel0.youtubeName}`}
                          n={0}
                          mediaShortCode={props.media.shortCode}
                          additionalParams={`&lc=${commentLevel0.commentId}`}
                        ></LinkToYoutube>
                        &nbsp;
                        <span className="text-gray-800">
                          {fullDate(new Date(commentLevel0.publishedAt))}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </CommentSmall>
                </div>
                <div className="col-span-6"></div>
                <div className="col-span-1"></div>
                <div className="col-span-6">
                  {commentLevel0.children.map((commentLevel1) => {
                    return (
                      <CommentSmall
                        key={commentLevel1.id}
                        comment={{
                          ...commentLevel1,
                          indexes: [],
                        }}
                        classNames=""
                      >
                        {props.media.source === 'youtube' ? (
                          <div className="flex text-sm">
                            {fullDate(new Date(commentLevel1.publishedAt))}
                            &nbsp;
                            <LinkToYoutube
                              text={`${commentLevel1.youtubeName}`}
                              n={0}
                              mediaShortCode={props.media.shortCode}
                              additionalParams={`&lc=${commentLevel1.commentId}`}
                            ></LinkToYoutube>
                          </div>
                        ) : (
                          <></>
                        )}
                      </CommentSmall>
                    );
                  })}
                </div>
                <div className="col-span-5"></div>
              </div>
            );
          })}
        </div>
      )}{' '}
      <Pagination
        disabled={loading}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadComments(w * 50);
        }}
      />
    </div>
  );
};

export const MediaAndComments = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(MediaAndCommentsComponent);
