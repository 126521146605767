import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { MatchCount } from '../models';
import { getWeeks } from './utils';

interface MatchesProps {
  user: UserState['user'];
}
const MatchesComponent = (props: MatchesProps) => {
  const [weeks, setWeeks] = useState<[string, string, string][]>([]);
  const [date, setDate] = useState<[string, string, string] | undefined>(
    undefined
  );
  const [expr, setExpr] = useState<string | undefined>(undefined);
  const [account, setAccount] = useState<string>('');
  const [matches, setMatches] = useState<MatchCount[]>([]);

  const loadMatches = async (
    d: [string, string, string] | undefined,
    am: 0 | 1 | 2
  ) => {
    const matches = await fetch(
      `${API_BASE_URL}/matches-admin/?${
        d ? `&date=${d[0]}TO${d[1]}` : ''
      }&types=${encodeURIComponent('["audios","texts","comments"]')}`,
      { credentials: 'include' }
    ).then((r) => r.json());

    setMatches(
      matches.sort((a: MatchCount, b: MatchCount) => {
        if (a.clusterName !== b.clusterName) {
          return a.clusterName > b.clusterName ? 1 : -1;
        }
        return a.audios < b.audios ? 1 : -1;
      })
    );
  };

  useEffect(() => {
    const w = getWeeks();
    let currentDate = w[0];
    setDate(currentDate);
    setWeeks(w.reverse());
    if (props.user) {
      loadMatches(currentDate, 2);
    }
  }, []);

  const fullyear = new Date().getFullYear();

  return (
    <div>
      <h1 className="pb-4">Matches</h1>
      <div className="p-4 text-lg text-purple-800 bg-purple-100 mb-4">
        {matches.length === 0 && <p>No matches</p>}
        {matches.length === 1 && <p>One media</p>}
        {matches.length > 1 && <p>{matches.length} matches</p>}
      </div>
      {matches.length > 0 && (
        <table className="table-auto">
          <thead className="">
            <tr>
              <th colSpan={1}>Cluster</th>
              <th colSpan={1}>Expression</th>
              <th colSpan={1}>Matches (matches audio)</th>
              <th colSpan={1}>Matches (matches text)</th>
              <th colSpan={1}>Matches (comment)</th>
            </tr>
          </thead>
          <tbody>
            {matches.map((match) => {
              return (
                <tr>
                  <td className="text-xs" width="46px">
                    {match.clusterName}
                  </td>
                  <td className="">{match.expressionText}</td>
                  <td>{match.audios}</td>
                  <td>{match.texts}</td>
                  <td>{match.comments}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export const Matches = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(MatchesComponent);
