import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/api';

import { Account, Cluster, Expression } from '../../models';
import { displayBigNumber, displayBigTime } from '../utils';
import { Tags } from '../Tags';

const removeAccount: { [a: string]: number } = {};

interface AccountInClusterProps {
  account: Account;
  cluster: Cluster;
  clusterExpressions: Expression[];
  reloadClusters: () => void;
  loadExpressions: () => void;
}
const AccountInClusterComponent = (props: AccountInClusterProps) => {
  const [expressions, setExpressions] = useState<Expression[]>([]);
  const [editing, setEditing] = useState<boolean>(false);

  const detach = (expressionId: number, source: string) => {
    fetch(`${API_BASE_URL}/actions/detach-expression`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        expressionId: expressionId,
        source: source,
      }),
    })
      .then((r) => r.text())
      .then((resp) => {
        props.loadExpressions();
      })
      .catch((err) => {
        console.error('error detach expression');
        console.error(err);
      });
  };
  const attach = (accountId: number, expressionId: number, source: string) => {
    fetch(`${API_BASE_URL}/actions/attach-expression`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        expressionId: expressionId,
        accountId: accountId,
        source: source,
      }),
    })
      .then((r) => r.text())
      .then((resp) => {
        props.loadExpressions();
      })
      .catch((err) => {
        console.error('error attach expression');
        console.error(err);
      });
  };

  useEffect(() => {}, []);

  let youtubeOfficial = props.clusterExpressions.find(
    (expr) => expr.youtubeOfficial === props.account.id
  );
  let youtubeOfficialExpr = '';
  if (youtubeOfficial) {
    youtubeOfficialExpr = youtubeOfficial.text;
  }
  let instagramOfficial = props.clusterExpressions.find(
    (expr) => expr.instagramOfficial === props.account.id
  );
  let instagramOfficialExpr = '';
  if (instagramOfficial) {
    instagramOfficialExpr = instagramOfficial.text;
  }
  return (
    <div className="col-span-12 grid grid-cols-12 pb-8">
      <div className="col-span-12">
        <Tags account={props.account}></Tags>
      </div>
      <div className="col-span-3 flex">
        <img
          alt="Social media thumbnail"
          width={30}
          height={30}
          src={props.account.thumbnail}
          className="pr-2"
        ></img>
        {props.account.youtubeUserId && (
          <a
            target="_blank"
            rel="noreferrer"
            className="underline font-bold"
            href={`https://www.youtube.com/@${props.account.name}/videos`}
          >
            @{props.account.name.slice(0, 25)}
          </a>
        )}
        {props.account.instagramUserId && (
          <a
            target="_blank"
            rel="noreferrer"
            className="underline font-bold"
            href={`https://www.instagram.com/${props.account.name}/`}
          >
            @{props.account.name}
          </a>
        )}
        {props.account.name.includes('.') && (
          <a
            target="_blank"
            rel="noreferrer"
            className="underline font-bold"
            href={props.account.name}
          >
            {props.account.name.replace('www.', '')}
          </a>
        )}
        &nbsp;
        {props.account.lang === 'en' && ' 🇬🇧'}
        {props.account.lang === 'fr' && ' 🇫🇷'}
        {props.account.lang === 'de' && ' 🇩🇪'}
        {props.account.lang === 'es' && ' 🇪🇸'}
        {props.account.lang === 'zh' && ' 🇨🇳'}
        {props.account.lang === 'ja' && ' 🇯🇵'}
        {props.account.lang === 'id' && ' 🇮🇩'}
        {props.account.lang === 'ko' && ' 🇰🇷'}
      </div>
      <div className="flex col-span-3 justify-center">
        <input
          onClick={(e) => {
            e.preventDefault();
            if (props.account.selection === 'all') return;
            fetch(`${API_BASE_URL}/actions/update-account-selection`, {
              credentials: 'include',
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                accountId: props.account.id,
                selection: 'all',
              }),
            })
              .then((r) => r.text())
              .then((resp) => {
                props.reloadClusters();
              })
              .catch((err) => {
                console.error('error update-account-selection');
                console.error(err);
              });
          }}
          className="m-0 p-0 mr-2"
          type="radio"
          checked={props.account.selection === 'all'}
          onChange={(e) => {}}
        ></input>
        <label className="text-sm m-0 p-0 flex items-center mr-2">All</label>
        &nbsp;
        <input
          onClick={(e) => {
            e.preventDefault();
            if (props.account.selection === 'filter') return;
            fetch(`${API_BASE_URL}/actions/update-account-selection`, {
              credentials: 'include',
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                accountId: props.account.id,
                selection: 'filter',
              }),
            })
              .then((r) => r.text())
              .then((resp) => {
                props.reloadClusters();
              })
              .catch((err) => {
                console.error('error update-account-selection');
                console.error(err);
              });
          }}
          className="m-0 p-0 mr-2"
          type="radio"
          checked={props.account.selection === 'filter'}
          onChange={(e) => {}}
        ></input>
        <label className="text-sm m-0 p-0 flex items-center">Filter</label>
        &nbsp;
        {['en', 'fr', 'de', 'es', 'ja', 'id', 'ko'].map((l) => {
          return (
            <span
              className={`flex flex-col items-center ${
                props.account.lang === l ? 'font-bold' : 'cursor-pointer'
              }`}
              style={{ width: '20px' }}
              key={l}
              onClick={(e) => {
                e.preventDefault();
                fetch(`${API_BASE_URL}/actions/update-account-lang`, {
                  credentials: 'include',
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    accountId: props.account.id,
                    lang: l,
                  }),
                })
                  .then((r) => r.text())
                  .then((resp) => {
                    props.reloadClusters();
                  })
                  .catch((err) => {
                    console.error('error update-account-lang');
                    console.error(err);
                  });
              }}
            >
              {l}
            </span>
          );
        })}
      </div>
      <div className="flex col-span-1 font-bold text-stone-700 text-sm">
        <span title={`${displayBigNumber(props.account.followers)} followers`}>
          {displayBigNumber(props.account.followers)} 👱
        </span>
      </div>
      <div className="flex col-span-1 font-bold text-stone-700 text-sm">
        <span
          title={`${displayBigNumber(props.account.views)} views over ${
            props.account.nbMedias
          } medias/videos indexed`}
        >
          {displayBigNumber(props.account.views)} 👁
        </span>
      </div>
      <div className="flex col-span-2 font-normal text-stone-500 text-sm">
        {props.account.nbMedias !== 0 && props.account.seconds !== 0 && (
          <span
            title={`${
              props.account.nbMedias
            } medias/videos indexed, totalling ~${displayBigTime(
              props.account.seconds
            )}`}
          >
            {props.account.nbMedias} 📹, (~
            {displayBigTime(props.account.seconds)})
          </span>
        )}
      </div>
      <div className="flex col-span-2">
        <button
          className="red btn small mr-2"
          onClick={(e) => {
            e.preventDefault();
            if (!removeAccount[props.account.id])
              removeAccount[props.account.id] = 0;
            removeAccount[props.account.id] += 1;

            if (removeAccount[props.account.id] === 3) {
              fetch(`${API_BASE_URL}/actions/delete-account`, {
                credentials: 'include',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  accountId: props.account.id,
                }),
              })
                .then((r) => r.text())
                .then((resp) => {
                  props.reloadClusters();
                })
                .catch((err) => {
                  console.error('error delete-account');
                  console.error(err);
                });
            }
          }}
        >
          Supprimer
        </button>
      </div>
      <div className="col-span-12 flex text-sm mt-1">
        {youtubeOfficialExpr ? (
          <p className="flex items-center">
            <img
              className="mr-1"
              src="/youtube.png"
              width="16px"
              height="auto"
            ></img>{' '}
            associated with&nbsp;<b>{youtubeOfficialExpr}</b>&nbsp;
            <a
              className="font-bold underline cursor-pointer"
              title={`Detach this account as official youtube account of ${youtubeOfficialExpr}`}
              onClick={() => {
                detach((youtubeOfficial as Expression).id, 'youtube');
              }}
            >
              unlink
            </a>
            &nbsp;&nbsp;
          </p>
        ) : undefined}

        {instagramOfficialExpr ? (
          <p className="flex items-center">
            <img
              className="mr-1"
              src="/instagram.png"
              width="16px"
              height="auto"
            ></img>{' '}
            associated with&nbsp;<b>{instagramOfficialExpr}</b>&nbsp;
            <a
              className="font-bold underline cursor-pointer"
              title={`Detach this account as official instagram account of ${instagramOfficialExpr}`}
              onClick={() => {
                detach((instagramOfficial as Expression).id, 'instagram');
              }}
            >
              unlink
            </a>
          </p>
        ) : undefined}
      </div>
      <div className="col-span-12 flex items-center">
        {editing ? (
          <input
            className="input-small mr-4"
            onChange={(e) => {
              if (!e.target.value) {
                setExpressions([]);
                return;
              }
              fetch(
                `${API_BASE_URL}/expressions?clusterId=${props.cluster.id}&like=${e.target.value}`,
                {
                  credentials: 'include',
                }
              )
                .then((r) => r.json())
                .then((resp) => {
                  setExpressions(resp);
                  console.log(resp);
                })
                .catch((err) => {
                  console.error('error expressions');
                  console.error(err);
                });
            }}
          ></input>
        ) : undefined}
        {editing ? (
          <a
            onClick={() => {
              setEditing(false);
              setExpressions([]);
            }}
            className="font-bold underline cursor-pointer text-sm mr-1"
          >
            Done
          </a>
        ) : (
          <a
            onClick={() => setEditing(true)}
            className="font-bold underline cursor-pointer text-sm mr-1"
          >
            Manage official
          </a>
        )}
        {expressions.map((expr) => {
          return (
            <>
              <a
                key={expr.text + 'yt'}
                onClick={() => {
                  attach(props.account.id, expr.id, 'youtube');
                }}
                className="text-sm font-bold underline cursor-pointer flex items-center mr-1"
                title={'Attach expression to account (Youtube)'}
              >
                <img
                  className="mr-1"
                  src="/youtube.png"
                  width="16px"
                  height="auto"
                ></img>{' '}
                official of {expr.text}
              </a>
              <a
                key={expr.text + 'in'}
                onClick={() => {
                  attach(props.account.id, expr.id, 'instagram');
                }}
                className="text-sm font-bold underline cursor-pointer flex items-center mr-1"
                title={'Attach expression to account (Instagram)'}
              >
                <img
                  className="mr-1"
                  src="/instagram.png"
                  width="16px"
                  height="auto"
                ></img>{' '}
                official of {expr.text}
              </a>
            </>
          );
        })}
      </div>
    </div>
  );
};

export const AccountInCluster = AccountInClusterComponent;
