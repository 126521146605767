import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { UserState } from '../reducers/user';

const getUserState = createSelector(
  (state: RootState) => state.user,
  (state: UserState) => state
);

export const getUser = createSelector(
  getUserState,
  (state: UserState) => state.user
);

export const getDefaultClusterId = createSelector(
  getUserState,
  (state: UserState) => {
    if (state.user && state.user.brands && state.user.brands[0])
      return state.user.brands[0].cluster;
    return undefined;
  }
);
