import React from 'react';
import { isYesterday } from './groupsInBoard';

interface LineDateProps {
  date: string;
}
const LineDateComponent = (props: LineDateProps) => {
  const d = new Date(props.date);
  const fullyear = d.getFullYear();
  const today =
    new Date().getMonth() === d.getMonth() &&
    new Date().getUTCDate() === d.getUTCDate();
  const dplusone = new Date(new Date(props.date).getTime() + 24 * 3600000);
  const yesterday =
    new Date().getMonth() === dplusone.getMonth() &&
    new Date().getUTCDate() === dplusone.getUTCDate();

  console.log(d.toISOString(), dplusone.toISOString(), d.toISOString());
  let str = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' })
    .format(new Date(props.date))
    .replace(fullyear + '', '')
    .replace(',', '');
  if (today) str = 'Today';
  if (yesterday) str = 'Yesterday';

  return (
    <div className="pb-2">
      <h3 className="text-base text-gray-500 font-normal pt-2 pb-2">{str}</h3>
    </div>
  );
};

export const LineDate = LineDateComponent;
