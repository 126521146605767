import isEqual from 'lodash.isequal';
import React, { useState, useEffect } from 'react';

const Approved = () => (
  <img
    style={{ width: '28px', height: '28px' }}
    className="mr-1"
    src="/approved.png"
  ></img>
);
const Unchecked = () => (
  <img
    style={{ width: '28px', height: '28px' }}
    className="mr-1"
    src="/unchecked.png"
  ></img>
);
const Hint = (props: { text: string }) => <p className="hint">{props.text}</p>;

interface Option {
  value: any;
  label: string;
  hint?: string;
}
export const FunSelectMulti = (props: {
  filtering: boolean;
  disabled: boolean;
  options: Option[];
  selectedOptions: string[];
  selectOptions: (o: string[]) => void;
  setFiltering: (o: boolean) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const sos = props.options.filter((o) =>
    props.selectedOptions.find((so: any) => o.value === so)
  );

  if (expanded) {
    return (
      <div className="fun-select multi">
        {props.options.map((o) => {
          const selected = props.selectedOptions.find(
            (so: any) => o.value === so
          );
          return (
            <div
              key={o.label}
              className={`option ${props.disabled ? 'disabled' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                if (props.disabled) return;
                if (selected) {
                  props.selectOptions(
                    props.selectedOptions.filter((so: any) => so !== o.value)
                  );
                } else {
                  props.selectOptions(props.selectedOptions.concat([o.value]));
                }
                // setExpanded(false);
                // props.setFiltering(false);
              }}
            >
              <div className="in">
                {selected ? <Approved /> : <Unchecked />}
                <span
                  className="flex items-center"
                  dangerouslySetInnerHTML={{ __html: o.label }}
                ></span>
              </div>
              {o.hint ? <Hint text={o.hint} /> : undefined}
            </div>
          );
        })}
      </div>
    );
  }

  if (sos.length) {
    return (
      <div className="fun-select">
        {sos.map((so) => {
          return (
            <div
              key={so.value}
              className={`option ${props.disabled ? 'disabled' : ''} ${
                props.filtering ? 'filtering' : ''
              }`}
              onClick={(e: any) => {
                e.preventDefault();
                if (props.disabled || props.filtering) return;
                // setExpanded(true);
                // props.setFiltering(true);
              }}
            >
              <div className="in">
                <Approved />
                {(so as Option).label}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="fun-select">
      <div
        className={`option ${props.disabled ? 'disabled' : ''} ${
          props.filtering ? 'filtering' : ''
        }`}
        onClick={(e: any) => {
          e.preventDefault();
          if (props.disabled || props.filtering) return;
          // setExpanded(true);
          // props.setFiltering(true);
        }}
      >
        <Unchecked />
        Select option
      </div>
    </div>
  );
};
