import { Board, Group } from '../../models';

const day = 24 * 60 * 60 * 1000;

export const groupsInPool = (
  boards: Board[],
  verticalGroups: Board[][]
): Group[] => {
  let remainingHorizontals: Board[] = [].concat(
    boards.filter((b: any) => b.mediaSource !== 'instagram') as any
  );
  let groups: Group[] = [];
  let v = verticalGroups.concat([]);
  let i = 0;
  while (remainingHorizontals.length) {
    if ([0, 3, 5, 7].includes(i) && v[0]) {
      const verticals = v[0];
      v = v.slice(1);
      const horizontals = remainingHorizontals.slice(0, 5);
      remainingHorizontals = remainingHorizontals.slice(5);
      groups.push({
        verticals: verticals,
        horizontals: horizontals,
      });
      i += 1;
    } else {
      groups.push({
        horizontals: remainingHorizontals.slice(0, 2),
      });
      remainingHorizontals = remainingHorizontals.slice(2);
      i += 1;
    }
  }

  return groups;
};

export const isYesterday = (date: Date) => {
  const now = new Date();
  const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  return (
    date.getDate() === oneDayAgo.getDate() &&
    date.getMonth() === oneDayAgo.getMonth()
  );
};
export const isSameDay = (date1: Date, date2: Date): true | number => {
  console.log(date1, date2);
  if (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth()
  ) {
    return true;
  }
  if (date1.getTime() > date2.getTime()) {
    console.warn('date1 must be before date2');
    return true;
  }

  const a = date1.setHours(23, 50);
  const b = date2.setHours(23, 59);
  return Math.floor(date2.getTime() / day - date1.getTime() / day);
};

export const createVerticalGroups = (boards: Board[]): Board[][] => {
  const verticals = boards.filter((b) => b.mediaSource === 'instagram');
  const groups = [];
  let i = 2;
  if (verticals.length < 20) {
    i = 3;
  } else if (verticals.length < 30) {
    i = 4;
  } else if (verticals.length < 40) {
    i = 5;
  } else if (verticals.length < 50) {
    i = 6;
  } else if (verticals.length < 70) {
    i = 7;
  } else {
    i = 8;
  }
  const v = verticals.concat([]);
  while (v.length > 0) {
    if (v.length === 1) {
      groups[groups.length - 1].push(v[0]);
      break;
    }
    groups.push(v.splice(0, Math.floor(Math.random() * 2 + i)));
  }
  return groups;
};
