import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';

interface StatsProps {
  user: UserState['user'];
}
const StatsComponent = (props: StatsProps) => {
  const [expressions, setExpressions] = useState<any>({});
  const [stats, setStats] = useState<any>(undefined);
  const [clusters, setClusters] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/stats`, { credentials: 'include' })
      .then((r) => r.json())
      .then((resp) => {
        setStats(resp.stats);
        setClusters(resp.clusters);
        setExpressions(resp.expressions);
      });
  }, []);

  if (!stats) {
    return <div>loading</div>;
  }
  return (
    <div>
      {clusters.map((cluster: any) => {
        return stats.map((stat: any) => {
          return (
            <div key={stat.label + cluster.id}>
              <h5 className="pt-4 text-normal">
                {stat.label}, cluster{' '}
                <span className="font-bold">{cluster.name}</span> from{' '}
                {stat.period[0].slice(0, 19)} to {stat.period[1].slice(0, 19)}
              </h5>
              <div className="pt-2 grid grid-cols-12">
                {['comments', 'chunks'].map((instanceType) => {
                  return (
                    <div key={instanceType} className="col-span-4">
                      <h5 className="text-sm font-bold">{instanceType}</h5>
                      <table>
                        <thead></thead>
                        <tbody>
                          {stat[instanceType][cluster.id].map(
                            (countAndExpr: any) => {
                              return (
                                <tr key={countAndExpr.expression}>
                                  <td>
                                    {expressions[countAndExpr.expression]}
                                  </td>
                                  <td>{countAndExpr.count}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        });
      })}
    </div>
  );
};

export const Stats = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(StatsComponent);
