import React from 'react';

export const Pagination = (props: {
  disabled: boolean;
  pages: number[];
  currenPage: number;
  onClick: (e: number) => void;
}) => (
  <div className="flex justify-start mb-4">
    {props.pages &&
      props.pages.map((w) => {
        return (
          <button
            key={w}
            className={`mr-2 text-sm p-1 bg-gray-100 ${
              w === props.currenPage ? 'font-bold' : ''
            } ${
              props.disabled ? 'text-gray-500 cursor-default' : 'text-black'
            }`}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              props.onClick(w);
            }}
          >
            {w}
          </button>
        );
      })}
  </div>
);
