import React, { useState } from 'react';

export const LinkToYoutube = (props: {
  text: string;
  n: number;
  mediaShortCode: string;
  classNames?: string;
  additionalParams?: string;
  onMouseEnter?: () => void;
}) => {
  return (
    <a
      href={`https://www.youtube.com/watch?v=${props.mediaShortCode}&t=${
        60 * props.n
      }${props.additionalParams || ''}`}
      className={
        props.classNames || 'font-bold flex items-center justify-start'
      }
      target="_blank"
      rel="noreferrer"
      onMouseEnter={props.onMouseEnter}
    >
      {props.text.includes('Part') ? (
        <>
          <img src="/link.png" width="12px" height="12px"></img>&nbsp;
        </>
      ) : undefined}
      {props.text}
    </a>
  );
};
