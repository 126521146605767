import React, { useState } from 'react';
import 'intersection-observer';
import { Link } from 'wouter';

import { API_BASE_URL } from '../../constants/api';
import { Comment, User } from '../../models';

interface NewCommentYoutubeProps {
  user: undefined | User;
  comment?: Comment;
  videoId?: string;
}
export const NewCommentYoutube = (props: NewCommentYoutubeProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [redacting, setRedacting] = useState<boolean>(false);
  const [youtubeError, setYoutubeError] = useState<string>('');
  const [text, setText] = useState<string>('');

  if (!props.user) {
    return (
      <div className="p-2">
        <p className="text-lg">
          Log in and link your Youtube account in order to comment
        </p>
      </div>
    );
  }

  if (!props.user.youtubeUserId) {
    return (
      <div className="p-2">
        <p className="text-lg">
          <Link href="/account">
            <button className={`underline`}>Link your Youtube account</button>
          </Link>
          in order to comment
        </p>
      </div>
    );
  }

  return (
    <div className="py-2 w-full">
      {redacting && (
        <textarea
          onChange={(e) => setText(e.target.value)}
          rows={4}
          className=" p-2 new-comment border-2 border-gray-400"
        ></textarea>
      )}
      <div className="flex items-center mt-2 ">
        <button
          onClick={() => {
            setYoutubeError('');
            setRedacting(!redacting);
          }}
          className="text-sm underline font-bold flex items-center mr-4"
        >
          {!redacting && (
            <img
              className="mr-2"
              src="/pen.png"
              style={{ width: '16px', height: '16px' }}
            ></img>
          )}
          {redacting
            ? 'Cancel'
            : props.videoId
            ? 'Post comment'
            : 'Respond to comment or thread'}
        </button>
        {redacting && (
          <button
            onClick={() => {
              if (text) {
                fetch(`${API_BASE_URL}/actions/youtube-create-comment`, {
                  credentials: 'include',
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    text,
                    ...(props.videoId ? { videoId: props.videoId } : {}),
                    ...(props.comment
                      ? { parentId: props.comment.commentId }
                      : {}),
                  }),
                })
                  .then((r) => r.text())
                  .then((resp) => {
                    setLoading(false);
                    setRedacting(false);
                    setText('');
                  })
                  .catch((err) => {
                    setYoutubeError(
                      'Something went wrong (1), please contact administrator'
                    );
                    console.error('error youtube-revoke');
                    console.error(err);
                    setLoading(false);
                  });
              }
            }}
            className={`text-sm underline font-bold flex items-center ${
              text ? '' : 'cursor-not-allowed text-gray-500'
            }`}
          >
            Post comment
          </button>
        )}
      </div>
    </div>
  );
};
