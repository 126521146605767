import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../reducers';
import { getUser } from '../../selectors/user';
import { Media } from '../../models';
import { fullDate, hrefBasedOnShortCode } from '../utils';
import { MediaBlockSmall } from './MediaBlockSmall';

interface LineMyMediaProps {
  media: Media;
  onClick: () => void;
}
const LineMyMediaComponent = (props: LineMyMediaProps) => {
  const divRef = useRef(null);
  useEffect(() => {}, []);

  let viewsPerHour = props.media.viewsPerHour;
  if (props.media.viewsPerHour !== 0) {
    viewsPerHour = Math.floor(viewsPerHour);
  }

  return (
    <>
      <div
        key={`${props.media.id}-`}
        className="flex justify-start grid grid-cols-12 pb-8"
        ref={divRef}
      >
        <div className="col-span-4 flex items-center justify-start relative">
          <div className="">
            <span className="">
              {fullDate(new Date(props.media.publishedAt))}
            </span>
          </div>
          {props.media.source === 'youtube' ? (
            <>
              <img
                className="pr-1 pl-1"
                src="/youtube.png"
                width="30px"
                height="auto"
              ></img>
            </>
          ) : undefined}
          {props.media.source === 'instagram' ? (
            <>
              <img
                className="pr-1 pl-1"
                src="/instagram.png"
                width="30px"
                height="auto"
              ></img>
            </>
          ) : undefined}
          {props.media.type === 'article' ? (
            <>
              <img
                className="pr-1 pl-1"
                src="/earth.png"
                width="30px"
                height="auto"
              ></img>
            </>
          ) : undefined}
          <MediaBlockSmall
            views={props.media.views}
            source={props.media.source}
            type={props.media.type}
            followers={props.media.accountFollowers}
            accountName={props.media.accountName}
          />
          <span
            className="col-span-1 flex items-center text-gray-900 text-sm pr-2"
            title={`${props.media.comments} comment(s) posted on the video`}
          >
            &nbsp;
            <img src="/comment.png" width="16px" height="16px"></img>
            &nbsp;
            {props.media.comments}
          </span>
        </div>
        <div className="col-span-8 flex items-center justify-start relative">
          {props.media.thumbnail ? (
            <img
              alt="youtube thumbnail"
              src={props.media.thumbnail}
              style={{ width: 'auto', height: '40px' }}
              width="auto"
              height="40px"
            />
          ) : undefined}
          <a
            className="font-bold rounded pl-2 px-3 text-sm flex justify-center items-start"
            href={hrefBasedOnShortCode(
              props.media.shortCode,
              props.media.source,
              props.media.type
            )}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              props.onClick();
            }}
          >
            <i>{`${
              props.media.name.length > 70
                ? `${props.media.name.slice(0, 70)}…`
                : props.media.name
            }`}</i>
            &nbsp;
          </a>
        </div>
      </div>
    </>
  );
};

export const LineMyMedia = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(LineMyMediaComponent);
