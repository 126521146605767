import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../constants/api';

import { Cluster, Account, Alt, Tag } from '../models';

interface TagsProps {
  account: Account;
}
const TagsComponent = (props: TagsProps) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [tmpTags, setTmpTags] = useState<Tag[]>([]);

  const attachTag = (t: string) => {
    fetch(`${API_BASE_URL}/attach-tag`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accountId: props.account.id, tagId: t }),
    })
      .then((r) => r.text())
      .then((resp) => {
        console.log(resp);
        loadAllTags();
      })
      .catch((err) => {
        console.error('error get tags');
        console.error(err);
      });
  };

  const loadAllTags = () => {
    fetch(`${API_BASE_URL}/tags`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((r) => r.json())
      .then((resp) => {
        console.log(resp);
        setTmpTags(resp.tags);
      })
      .catch((err) => {
        console.error('error get tags');
        console.error(err);
      });
  };

  const loadTags = () => {
    fetch(`${API_BASE_URL}/tags?accountId=${props.account.id}`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((r) => r.json())
      .then((resp) => {
        console.log(resp);
        setTags(resp.tags);
      })
      .catch((err) => {
        console.error('error get tags');
        console.error(err);
      });
  };

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <div>
      <div className="flex items-center pb-2">
        {tags.map((t) => {
          return (
            <span
              key={t.text}
              title="delete tag"
              className="tag px-2 py-1 text-sm mr-2 cursor-pointer text-white rounded"
              style={{ background: t.color }}
            >
              {t.text}
            </span>
          );
        })}
        <span
          className="tag px-2 py-1 mr-2 bg-white text-sm cursor-pointer rounded"
          onClick={() => {
            loadAllTags();
          }}
        >
          + Add tag(s)
        </span>
        {tmpTags.map((t) => {
          return (
            <span
              key={t.text}
              onClick={() => {
                attachTag(t.id + '');
              }}
              title="Add tag"
              className="tag px-2 py-1 mr-2 bg-slate-800 text-white text-sm cursor-pointer rounded"
            >
              Attach "{t.text}"
            </span>
          );
        })}
      </div>
    </div>
  );
};

export const Tags = TagsComponent;
