import { API_BASE_URL } from '../../constants/api';
import { Media } from '../../models';

class MediaLoader {
  medias: { [id: string]: Media } = {};
  ongoingRequests: { [id: string]: true } = {};

  constructor() {}

  load = async (mediaId: number) => {
    if (this.medias[mediaId]) return Promise.resolve(this.medias[mediaId]);
    if (this.ongoingRequests[mediaId]) {
      const afterWait = await new Promise<Media>((resolve) => {
        let resolved = false;
        setTimeout(() => {
          if (this.medias[mediaId]) {
            resolve(this.medias[mediaId] || null);
            resolved = true;
          }
        }, 400);
        setTimeout(() => {
          if (this.medias[mediaId] && !resolved) {
            resolve(this.medias[mediaId] || null);
            resolved = true;
          }
        }, 800);
        setTimeout(() => {
          if (this.medias[mediaId] && !resolved) {
            resolve(this.medias[mediaId] || null);
            resolved = true;
          }
        }, 1400);
      });
      if (afterWait) {
        return Promise.resolve(afterWait);
      }
    }
    this.ongoingRequests[mediaId] = true;
    const resp = await fetch(`${API_BASE_URL}/medias-by-id?ids=${mediaId}`, {
      credentials: 'include',
    });
    const json = await resp.json();
    delete this.ongoingRequests[mediaId];
    if (json.medias[0]) {
      this.medias[mediaId] = json.medias[0];
    }
    return this.medias[mediaId];
  };
}

let mediaLoader: undefined | MediaLoader = undefined;
export const getMediaLoader = (): MediaLoader => {
  if (mediaLoader) return mediaLoader;
  mediaLoader = new MediaLoader();
  return mediaLoader;
};
