import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../constants/api';

import { Cluster, Expression } from '../models';
import { displayBigNumber, displayBigTime, debounce } from './utils';
import { Expressions } from './Expressions';
import { AccountInCluster } from './utils/AccountInCluster';

const removeAccount: { [a: string]: number } = {};

interface SelectedClusterProps {
  cluster: Cluster;
  reloadClusters: () => void;
}
const SelectedClusterComponent = (props: SelectedClusterProps) => {
  const [newAccount, setNewAccount] = useState<string>('');
  const [expressions, setExpressions] = useState<Expression[]>([]);
  const [newAccountSource, setNewAccountSource] = useState<string>('youtube');
  const [description, setDescription] = useState<string>('');
  const [keywords, setKeywords] = useState<string>('');

  const debounceDescription = debounce((e: any) => {
    setDescription(e.target.value);
    fetch(`${API_BASE_URL}/actions/update-cluster`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clusterId: props.cluster.id,
        description: e.target.value || '',
        keywords: keywords || '',
      }),
    })
      .then((r) => r.text())
      .then((resp) => {})
      .catch((err) => {
        console.error('error update-cluster');
        console.error(err);
      });
  }, 1000);

  const debounceKeywords = debounce((e: any) => {
    setKeywords(e.target.value);
    fetch(`${API_BASE_URL}/actions/update-cluster`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clusterId: props.cluster.id,
        keywords: e.target.value || '',
        description: description || '',
      }),
    })
      .then((r) => r.text())
      .then((resp) => {})
      .catch((err) => {
        console.error('error update-cluster');
        console.error(err);
      });
  }, 1000);

  const loadExpressions = () => {
    console.log('loadExpressions');
    fetch(`${API_BASE_URL}/expressions?clusterId=${props.cluster.id}`, {
      credentials: 'include',
    })
      .then((r) => r.json())
      .then((resp) => {
        setExpressions(
          resp.map((e: any) => {
            return {
              ...e,
              alts: (e.altss || []) as Expression[],
            };
          })
        );
      })
      .catch((err) => {
        console.error('error expressions');
        console.error(err);
      });
  };

  useEffect(() => {
    setDescription(props.cluster.description);
    setKeywords(props.cluster.keywords);
    loadExpressions();
  }, []);

  return (
    <div>
      <h2 className="pb-4">{props.cluster.name}</h2>
      <form>
        <div className="grid grid-cols-12 pb-8">
          <div className="col-span-9">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Description
            </label>
            <div className="mt-2 w-full">
              <textarea
                className="w-full textarea-normal"
                rows={3}
                defaultValue={description}
                onChange={debounceDescription}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 pb-8">
          <div className="col-span-9">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Keywords used for accounts of type filter
            </label>
            <div className="mt-2 w-full">
              <textarea
                className="w-full textarea-normal"
                placeholder="bike,biking,vélo,ebike,e-bike,cyclisme,cycling,gravel,vtt"
                rows={2}
                defaultValue={keywords}
                onChange={debounceKeywords}
              ></textarea>
            </div>
          </div>
        </div>
      </form>

      <Expressions
        cluster={props.cluster}
        loadExpressions={loadExpressions}
        expressions={expressions}
      ></Expressions>

      <h2 className="pb-4 mt-8">Comptes</h2>
      <div className="flex pb-8">
        <input
          className="input-normal mr-4"
          value={newAccount}
          onChange={(e) => {
            setNewAccount(e.target.value);
          }}
        ></input>
        &nbsp;
        <input
          onClick={(e) => {
            setNewAccountSource('youtube');
          }}
          className="m-0 p-0 mr-2"
          type="radio"
          checked={newAccountSource === 'youtube'}
          onChange={(e) => {}}
        ></input>
        <label className="text-sm m-0 p-0 flex items-center mr-2">
          youtube
        </label>
        &nbsp;
        <input
          onClick={(e) => {
            setNewAccountSource('instagram');
          }}
          className="m-0 p-0 mr-2"
          type="radio"
          checked={newAccountSource === 'instagram'}
          onChange={(e) => {}}
        ></input>
        <label className="text-sm m-0 p-0 flex items-center mr-2">
          instagram
        </label>
        &nbsp;
        <button
          disabled={
            !newAccount ||
            !!props.cluster.accounts.find((a) => a.name === newAccount)
          }
          onClick={(e) => {
            e.preventDefault();
            fetch(`${API_BASE_URL}/actions/add-to-cluster`, {
              credentials: 'include',
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                instagramName:
                  newAccountSource === 'instagram' ? newAccount : '',
                youtubeName: newAccountSource === 'youtube' ? newAccount : '',
                clusterName: props.cluster.name,
                selection: 'all',
                source: newAccountSource,
              }),
            })
              .then((r) => r.text())
              .then((resp) => {
                setNewAccount('');
                props.reloadClusters();
              })
              .catch((err) => {
                console.error('error add-to-cluster');
                console.error(err);
              });
          }}
          className="btn"
        >
          Add account
        </button>
      </div>
      <div className="grid grid-cols-12 ">
        <div className="col-span-12">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Accounts
          </label>
        </div>
        {props.cluster.accounts.map((a) => {
          return (
            <AccountInCluster
              clusterExpressions={expressions}
              cluster={props.cluster}
              account={a}
              reloadClusters={props.reloadClusters}
              loadExpressions={loadExpressions}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SelectedCluster = SelectedClusterComponent;
