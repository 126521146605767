import React, { useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';

interface JobsProps {
  user: UserState['user'];
}
const JobsComponent = (props: JobsProps) => {
  const [jobs, setJobs] = useState<any>([]);
  const [days, setDays] = useState<number>(4);

  const loadJobs = () => {
    fetch(`${API_BASE_URL}/jobs`,
    {
      credentials: 'include',
      method: 'GET',
      headers: { "Content-Type": "application/json" },
    })
    .then((r) => r.json())
    .then(jobs => {
      console.log(jobs);
      setJobs(jobs);
    })
  }

  return (
    <div>
      <h1 className='pb-4'>Jobs</h1>
      <div className='flex pb-8'>
        <input className="input-normal mr-4 " type="number" value={days} onChange={e => {
           setDays(parseInt(e.target.value, 10))
        }}></input>
        &nbsp;
        <button
          disabled={typeof days !== 'number'}
          onClick={(e) => {
            e.preventDefault();
            fetch(`${API_BASE_URL}/actions/launch-job`,
              {
                credentials: 'include',
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  type: 'download',
                  days: days,
                }),
              })
              .then((r) => r.text())
              .then((resp) => { })
              .catch(err => {
                console.error('error launch-job');
                console.error(err);
              });
          }}
          className="btn"
        >Launch job (download)</button>
      </div>
      <div className='flex pb-8'>
        <button
          disabled={typeof days !== 'number'}
          onClick={(e) => {
            e.preventDefault();
            fetch(`${API_BASE_URL}/actions/launch-job`,
              {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  type: 'insert',
                }),
              })
              .then((r) => r.text())
              .then((resp) => { })
              .catch(err => {
                console.error('error launch-job');
                console.error(err);
              });
          }}
          className="btn"
        >Launch job (insert)</button>
      </div>
      <div>
        <button
            disabled={typeof days !== 'number'}
            onClick={(e) => {
              e.preventDefault();
              loadJobs();
            }}
            className="btn"
          >Reload</button>
      </div>
      <div>
        { jobs.map((j: any) => {
          return <div key={j.id}>
            <p className='font-bold'>{j.createdAt} {j.type} {j.params}</p>
            <pre>{j.stdout}</pre>
          </div>

        })}
      </div>
    </div>
  );
};

export const Jobs = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(JobsComponent);

