import React, { useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { useLocation } from 'wouter';
import { getMe } from '../actions/user';

interface SignUpProps {
  user: UserState['user'];
  getMe: () => void;
}
const SignUpComponent = (props: SignUpProps) => {
  const [location, navigate] = useLocation();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const disabled =
    typeof email !== 'string' ||
    typeof password !== 'string' ||
    !password.length ||
    !email.length ||
    emailError;

  if (success) {
    return (
      <div>
        <h1>Sign up</h1>
        <p className="my-4 success text-2xl">
          Success ! Please check your email to validate your email address !
        </p>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className={'max-w-2xl'}>
        <h1>Sign up</h1>
        <br />
        <div className="rounded p-4 border-2 border-gray-400 text-gray-600">
          Almost Friends is at the moment in beta, and only proposed to selected
          industries / verticals. You may not be able to sign up with your email
          address. Please contact hello@almostfriends.ai if you wish to use
          Almost Friends and can't sign up.
        </div>
        <br />
        <form>
          <div className="pb-4">
            <label className="" htmlFor="email">
              Email address
            </label>
            <input
              className={`input-big ${emailError ? 'border-error' : ''} ${
                !emailError && email.length ? 'border-success' : ''
              }`}
              id="email"
              type="text"
              placeholder="Email address"
              onChange={(e) => {
                const m = (e.target.value || '').match(
                  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
                );
                setServerError('');
                if (m && m[0] && m[0].length === e.target.value.length) {
                  setEmail(e.target.value);
                  setEmailError('');
                } else {
                  setEmail('');
                  setEmailError('Invalid email address');
                }
              }}
            ></input>
            {emailError && (
              <p className="mt-1 font-bold error max-w-2xl">{emailError}</p>
            )}
          </div>
          <div className="pb-8">
            <label className="" htmlFor="password">
              Password
            </label>
            <input
              className={`input-big ${password.length ? 'border-success' : ''}`}
              id="password"
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                setServerError('');
              }}
            ></input>
          </div>
          {serverError && (
            <p className="mt-1 mb-2 font-bold error max-w-2xl">{serverError}</p>
          )}
          <div className="pb-4">
            {disabled ? (
              <div className={`above-btn blue disabled big`}>
                <button disabled type="button">
                  Sign up
                </button>
              </div>
            ) : (
              <div className={`above-btn blue big`}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    let failed = false;
                    fetch(`${API_BASE_URL}/actions/sign-up`, {
                      method: 'POST',
                      credentials: 'include',
                      body: JSON.stringify({
                        email: email,
                        password: password,
                      }),
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    })
                      .then((r) => {
                        if (r.ok) {
                          return r.text();
                        }
                        failed = true;
                        return r.text();
                      })
                      .then((resp) => {
                        if (failed) {
                          setServerError(resp);
                          return;
                        }
                        props.getMe();
                        setTimeout(() => {
                          navigate('/');
                        }, 500);
                      })
                      .catch((err) => {
                        console.error(err);
                        setServerError(err);
                      });
                  }}
                  type="submit"
                >
                  Sign up
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export const SignUp = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({
    getMe: () => {
      dispatch(getMe() as any);
    },
  })
)(SignUpComponent);
