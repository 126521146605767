import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../constants/api';

import { Cluster, Expression, Alt } from '../models';

const removeExpression: { [a: string]: number } = {};

const PBT = 'Preceded by "the"/"new"';
const FBN = 'Only if followed by a number';
const FBUS = 'Only if followed by an uppercase string';
const FBMO =
  'If followed by teither uppercase string either number or a mix of';

interface ExpressionsProps {
  expressions: Expression[];
  cluster: Cluster;
  loadExpressions: () => void;
}
const ExpressionsComponent = (props: ExpressionsProps) => {
  const [newExpressionText, setNewExpressionText] = useState<string>('');
  const [alts, setAlts] = useState<Alt[]>([]);
  const [editingAlts, setEditingAlts] = useState<{ [a: string]: boolean }>({});

  const deleteExpression = (expr: Expression) => {
    fetch(`${API_BASE_URL}/actions/delete-expression`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        expressionId: expr.id,
        clusterId: expr.cluster,
      }),
    })
      .then((r) => r.text())
      .then((resp) => {
        props.loadExpressions();
      })
      .catch((err) => {
        console.error('error update-expression-alts');
        console.error(err);
      });
  };

  const updateExpressionAlts = (expr: Expression, alts: Alt[]) => {
    fetch(`${API_BASE_URL}/actions/update-expression-alts`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        expressionId: expr.id,
        clusterId: expr.cluster,
        alts: alts,
      }),
    })
      .then((r) => r.text())
      .then((resp) => {
        props.loadExpressions();
      })
      .catch((err) => {
        console.error('error update-expression-alts');
        console.error(err);
      });
  };

  const createExpression = (text: string) => {
    fetch(`${API_BASE_URL}/actions/create-expression`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clusterId: props.cluster.id,
        text: text,
      }),
    })
      .then((r) => r.text())
      .then((resp) => {
        props.loadExpressions();
      })
      .catch((err) => {
        console.error('error add-expression');
        console.error(err);
      });
  };

  useEffect(() => {}, []);

  return (
    <div>
      <h2 className="pb-4">Expressions</h2>
      <form className="pb-4">
        <div className="flex flex-row justify-start items-center">
          <label className="text-lg pr-4 font-bold">Expression / mot</label>
          <input
            className="mr-4 input-normal"
            onChange={(e) => {
              setNewExpressionText(e.target.value || '');
            }}
            placeholder="logitech"
            type="text"
            value={newExpressionText}
          />
          <button
            className="btn"
            onClick={(e) => {
              e.preventDefault();
              createExpression(newExpressionText);
            }}
            disabled={
              !newExpressionText ||
              !!props.expressions.find((c) => c.text === newExpressionText)
            }
          >
            Expression or word
          </button>
        </div>
      </form>
      {props.expressions.map((expr) => {
        return (
          <div
            key={expr.id}
            className="flex flex flex-wrap items-center justify-start p-2 [&:nth-child(even)]:bg-gray-100 "
          >
            <label className="text-lg text-blue-800 font-bold mr-2 p-0">
              {expr.text}
            </label>
            {editingAlts[expr.text]
              ? alts.map((a, i) => {
                  return (
                    <>
                      <input
                        key={expr.id}
                        className="input-small mr-2"
                        value={a.text}
                        onChange={(e) => {
                          setAlts(
                            alts.map((a, index) => {
                              if (index === i) {
                                return {
                                  text: e.target.value,
                                  pbt: false,
                                  fbn: false,
                                  fbus: false,
                                  fbmo: false,
                                };
                              }
                              return a;
                            })
                          );
                        }}
                      ></input>
                      <span
                        onClick={(e) => {
                          setAlts(
                            alts.map((a, index) => {
                              if (index === i) {
                                return {
                                  ...a,
                                  pbt: !a.pbt,
                                };
                              }
                              return a;
                            })
                          );
                        }}
                        title={PBT}
                        className={`cursor-pointer py-1 px-2 mr-1 font-bold bg-gray-100 text-green-500 text-sm ${
                          a.pbt && 'bg-green-200'
                        }`}
                      >
                        PB
                      </span>
                      <span
                        onClick={(e) => {
                          setAlts(
                            alts.map((a, index) => {
                              if (index === i) {
                                return {
                                  ...a,
                                  fbn: !a.fbn,
                                  fbus: false,
                                  fbmo: false,
                                };
                              }
                              return a;
                            })
                          );
                        }}
                        title={FBN}
                        className={`cursor-pointer py-1 px-2 mr-1 font-bold bg-gray-100 text-green-500 text-sm ${
                          a.fbn && 'bg-green-200'
                        }`}
                      >
                        N
                      </span>
                      <span
                        onClick={(e) => {
                          setAlts(
                            alts.map((a, index) => {
                              if (index === i) {
                                return {
                                  ...a,
                                  fbn: false,
                                  fbus: !a.fbus,
                                  fbmo: false,
                                };
                              }
                              return a;
                            })
                          );
                        }}
                        title={FBUS}
                        className={`cursor-pointer py-1 px-2 mr-1 font-bold bg-gray-100 text-green-500 text-sm ${
                          a.fbus && 'bg-green-200'
                        }`}
                      >
                        US
                      </span>
                      <span
                        onClick={(e) => {
                          setAlts(
                            alts.map((a, index) => {
                              if (index === i) {
                                return {
                                  ...a,
                                  fbn: false,
                                  fbus: false,
                                  fbmo: !a.fbmo,
                                };
                              }
                              return a;
                            })
                          );
                        }}
                        title={FBMO}
                        className={`cursor-pointer py-1 px-2 mr-1 font-bold bg-gray-100 text-green-500 text-sm ${
                          a.fbmo && 'bg-green-200'
                        }`}
                      >
                        MO
                      </span>
                    </>
                  );
                })
              : expr.alts.map((a) => {
                  return (
                    <span key={a.text} className="text-sm mr-2">
                      {a.text}
                      {['fbn', 'fbus', 'fbmo'].map((modif: any) => {
                        if ((a as any)[modif]) {
                          return (
                            <span
                              key={modif}
                              title={
                                ({ fbn: FBN, fbus: FBUS, fbmo: FBMO } as any)[
                                  modif
                                ]
                              }
                              className={`ml-2 cursor-pointer py-1 px-2 mr-1 font-bold bg-green-200 text-green-500 text-sm`}
                            >
                              {
                                ({ fbn: 'N', fbus: 'US', fbmo: 'MO' } as any)[
                                  modif
                                ]
                              }
                            </span>
                          );
                        }
                      })}
                    </span>
                  );
                })}
            {!editingAlts[expr.text] && (
              <button
                className="btn small mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  if (expr.alts.length) {
                    setAlts(expr.alts);
                  } else {
                    setAlts([]);
                  }
                  setEditingAlts({
                    [expr.text]: !editingAlts[expr.text],
                  });
                }}
              >
                Modifier les alts
              </button>
            )}
            {!editingAlts[expr.text] && (
              <button
                className="red btn small mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  deleteExpression(expr);
                }}
              >
                Supprimer
              </button>
            )}
            {editingAlts[expr.text] && (
              <button
                className="btn small mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setAlts(alts.concat([{ text: '' }]));
                }}
              >
                Nouveau
              </button>
            )}
            {editingAlts[expr.text] && (
              <button
                className="btn small mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  updateExpressionAlts(
                    expr,
                    alts.filter((a) => !!a.text)
                  );
                  setEditingAlts({});
                }}
              >
                Enregistrer
              </button>
            )}
            {editingAlts[expr.text] && (
              <button
                className="btn small"
                onClick={(e) => {
                  e.preventDefault();
                  setEditingAlts({
                    [expr.text]: false,
                  });
                }}
              >
                Annuler
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const Expressions = ExpressionsComponent;
