import React, { useState, useEffect } from 'react';

import { Board } from '../../models';

interface VideosManagerProps {
  boards: Board[];
}

const VideosManagerComponent = (props: VideosManagerProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="verticals overflow-hidden" style={{ background: '#faf' }}>
      {props.boards.map((b) => {
        return (
          <div className="with-video overflow-hidden">
            <video
              key={b.mediaFileUrl}
              autoPlay
              muted
              onClick={() => {
                console.log('clicked');
              }}
              onEnded={() => {
                console.log('ended');
              }}
            >
              <source type="video/mp4" src={b.mediaFileUrl}></source>
            </video>
            <div
              className="flex items-center pl-2"
              style={{ background: 'rgba(255,255,255,0)' }}
            >
              <a
                className="font-bold"
                href={'https://google.com'}
                target="_blank"
              >
                @{b.instagramName}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const VideosManager = VideosManagerComponent;
