import React from 'react';
import 'intersection-observer';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../constants/api';

import { RootState } from '../../reducers';
import { getUser } from '../../selectors/user';
import { Account, Media } from '../../models';
import { displayBigNumber, hrefBasedOnShortCode } from '../utils';

export const loadCommentsOfSameMediaAndExpr = (
  text: string,
  mediaId: number,
  cb: (resp: any) => void
) => {
  fetch(
    `${API_BASE_URL}/comments/?expressions=["${text}"]&mediaId=${mediaId}`,
    { credentials: 'include' }
  )
    .then((r) => r.json())
    .then((resp) => {
      cb(resp);
    });
};

interface MediaBlock2Props {
  name: Account['name'];
  mediaName: Media['name'];
  accountId: null | Media['accountId'];
  followers: Account['followers'];
  comments: Media['comments'];
  views: Media['views'];
  source: Media['source'];
  mediaViews: Media['views'];
  shortCode: Media['shortCode'];
  type: Media['type'];
  publishedAt: Media['publishedAt'];
  viewsPerHour: Media['viewsPerHour'];
  setDisplayText: () => void;
}
const MediaBlock2Component = (props: MediaBlock2Props) => {
  let viewsPerHour = props.viewsPerHour;
  if (viewsPerHour !== 0) {
    viewsPerHour = Math.floor(viewsPerHour);
  }
  if (viewsPerHour < 0) viewsPerHour = 0;

  return (
    <div className="flex flex-col grid grid-cols-12">
      <div className="flex items-center pb-2 col-span-3">
        {props.type === 'article' && (
          <img
            className="pr-1"
            alt="Earth"
            title="Text match"
            src="/earth.png"
            style={{ width: '25px', height: 'auto' }}
          />
        )}
        {props.type === 'description' && (
          <img
            className="pr-1"
            alt="Sheet"
            title="Match in text description"
            src="/written.png"
            style={{ width: '25px', height: 'auto' }}
          />
        )}
        {props.type === 'audioTranscript' && (
          <img
            className="pr-1"
            alt="Megaphone"
            title="Match in audio transcript"
            src="/megaphone.png"
            style={{ width: '25px', height: 'auto' }}
          />
        )}
        {['youtube', 'instagram'].includes(props.source) ? (
          <img
            className="pr-2"
            src={props.source === 'youtube' ? '/youtube.png' : '/instagram.png'}
            width="25px"
            height="auto"
          ></img>
        ) : null}
        {props.accountId && ['youtube', 'instagram'].includes(props.source) ? (
          <img
            className="pr-2"
            src={`${API_BASE_URL}/static/accounts/${props.accountId}.jpg`}
            style={{ width: '25px', height: 'auto' }}
          ></img>
        ) : null}
        {['youtube', 'instagram'].includes(props.source) ? (
          <span className="text-sm font-extrabold flex justify-start items-center">
            @{props.name}
          </span>
        ) : (
          <span className="text-sm font-extrabold flex justify-start items-center underline">
            {props.source}
          </span>
        )}
        {typeof props.followers === 'number' &&
        ['youtube', 'instagram'].includes(props.source) ? (
          <span
            className="text-sm font-extrabold flex justify-center items-center m-0 p-1 bg-gray-100 rounded border-gray-300 ml-1 mr-2"
            title={`${displayBigNumber(props.followers)} followers`}
          >
            {displayBigNumber(props.followers)}
            &nbsp;
            <img
              src="/person.png"
              width="16px"
              alt="Follower on social media"
            ></img>
            &nbsp;
          </span>
        ) : null}
      </div>
      <div className="flex items-center pb-2 col-span-9">
        <a
          className="text-normal font-extrabold flex justify-start items-center m-0 p-1"
          href={hrefBasedOnShortCode(props.shortCode, props.source, props.type)}
          target="_blank"
        >
          <i className="text-sm font-bold">
            {props.mediaName.length > 60
              ? `${props.mediaName.slice(0, 57)}…`
              : props.mediaName}
          </i>
        </a>
        &nbsp;
        {['youtube'].includes(props.source) &&
        typeof props.mediaViews === 'number' ? (
          <span
            style={{}}
            className="flex items-center text-gray-500 text-sm font-normal"
          >
            {displayBigNumber(props.mediaViews)}
            &nbsp;views&nbsp;
          </span>
        ) : null}
        {['youtube'].includes(props.source) &&
        typeof props.viewsPerHour === 'number' ? (
          <span
            style={{}}
            className=" flex items-center text-gray-500 text-sm font-normal"
          >
            {displayBigNumber(viewsPerHour)}
            &nbsp;per hour
          </span>
        ) : null}
        &nbsp;
        <button
          type="button"
          className="pl-4 text-sm font-bold text-black underline"
          onClick={(e) => {
            e.preventDefault();
            props.setDisplayText();
          }}
        >
          display text
        </button>
      </div>
    </div>
  );
};

export const MediaBlock2 = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(MediaBlock2Component);
