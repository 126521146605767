import { SAVE_FORR, SaveForrPayload } from '../actions/main';
import { Action } from '../models/';

export interface MainState {
  forr: null | string;
}
const initialState: MainState = {
  forr: null,
};

export default function forr(
  state: MainState = initialState,
  action: Action
): MainState {
  if (window.location.href.includes('localhost')) {
    console.log(
      '%cmain',
      'font-weight: bold; color: #FFF;padding:2px;background:#113;',
      action
    );
  }
  switch (action.type) {
    case SAVE_FORR: {
      const payload: SaveForrPayload = action.payload;
      return {
        ...state,
        forr: payload.forr,
      };
    }

    default:
      return state;
  }
}
