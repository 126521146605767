import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { MainState } from '../reducers/main';

const getMainState = createSelector(
  (state: RootState) => state.main,
  (state: MainState) => state
);

export const getForr = createSelector(
  getMainState,
  (state: MainState) => state.forr
);
