import React, { useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { LinkToInstagram } from './Playground';
import { MatchIndex, Chunk } from '../models';
import { LinkToYoutube } from './utils/LinkToYoutube';
import { highlightWords2 } from './utils';

interface ExploreProps {
  user: UserState['user'];
}
const ExploreComponent = (props: ExploreProps) => {
  const [expr, setExpr] = useState<string>('');
  const [account, setAccount] = useState<string>('');
  const [chunks, setChunks] = useState<Chunk[]>([]);

  return (
    <div>
      <h1 className="pb-4">Explore</h1>
      <form className="pb-4">
        <div className="flex flex-row justify-start items-center pb-4">
          <label className="text-lg pr-4 font-bold">
            Search for chunks linked to an account
          </label>
          <input
            className="mr-4 input-normal"
            onChange={(e) => {
              setAccount(e.target.value || '');
            }}
            placeholder="yoyobdt_"
            type="text"
            value={account}
          />
          <button
            className="btn"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              fetch(`${API_BASE_URL}/chunks-admin/?accountName=${account}`, {
                credentials: 'include',
              })
                .then((r) => r.json())
                .then((resp) => {
                  setChunks(resp.chunks);
                });
            }}
            disabled={!account}
          >
            Browse
          </button>
        </div>
        <div className="flex flex-row justify-start items-center">
          <label className="text-lg pr-4 font-bold">
            Look for occurrences of a word / expression
          </label>
          <input
            className="mr-4 input-normal"
            onChange={(e) => {
              setExpr(e.target.value || '');
            }}
            placeholder="pizza"
            type="text"
            value={expr}
          />
          <button
            className="btn"
            type="button"
            onClick={() => {
              fetch(`${API_BASE_URL}/chunks-like/?expr=${expr}`, {
                credentials: 'include',
              })
                .then((r) => r.json())
                .then((resp) => {
                  console.log(resp);
                  setChunks(resp.chunks);
                });
            }}
            disabled={!expr}
          >
            Browse
          </button>
        </div>
      </form>
      <div className="p-4 text-lg text-purple-800 bg-purple-100 mb-4">
        {chunks.length === 0 && <p>No results</p>}
        {chunks.length === 1 && <p>One result</p>}
        {chunks.length > 1 && <p>{chunks.length} results</p>}
      </div>
      {chunks.length > 0 && (
        <div>
          <div className="flex justify-start grid grid-cols-12">
            <div className="col-span-1"></div>
            <div className="col-span-3"></div>
            <div className="col-span-8">text</div>
          </div>
          {chunks.map((pr) => {
            let highlighted = pr.textp || 'matches not processed yet';
            let indexes: MatchIndex[] = [];
            (pr.matches || []).forEach((match, ind) => {
              indexes = indexes.concat(match.indexes);
            });
            /*
              We sort in backward order this way the locations
              of the indexes stay valids as <span> tags are added
            */
            indexes = indexes.sort((a, b) => {
              return a.location[0] > b.location[0] ? -1 : 1;
            });
            indexes.forEach((ind) => {
              highlighted = highlighted;
              /* highlightWords2(highlighted, [
                [ind.location, '#faf'],
              ]); */
            });
            const today =
              new Date().getMonth() ===
                new Date(pr.mediaPublishedAt).getMonth() &&
              new Date().getDay() === new Date(pr.mediaPublishedAt).getDay();
            return (
              <div
                key={`${pr.media}-${pr.n}`}
                className="flex justify-start grid grid-cols-12 pb-4"
              >
                <div className="col-span-1 flex flex-col justify-center">
                  <img
                    alt="youtube thumbnail"
                    src={pr.mediaThumbnail}
                    width="100%"
                  />
                  <span className="text-sm">
                    {today
                      ? 'today'
                      : new Intl.DateTimeFormat('en-GB', {
                          dateStyle: 'medium',
                        }).format(new Date(pr.mediaPublishedAt))}
                  </span>
                </div>
                <div className="col-span-3 text-sm pl-2 flex flex-col justify-center">
                  {pr.mediaSource === 'instagram' && (
                    <LinkToInstagram
                      mediaShortCode={pr.mediaShortCode}
                      accountName={pr.accountName}
                    ></LinkToInstagram>
                  )}
                  {pr.mediaSource === 'youtube' && (
                    <LinkToYoutube
                      text={`@${
                        pr.accountName.length > 30
                          ? `${pr.accountName.slice(0, 27)}…`
                          : pr.accountName
                      }`}
                      n={pr.n}
                      mediaShortCode={pr.mediaShortCode}
                    ></LinkToYoutube>
                  )}
                  <span className="text-sm font-normal">
                    {pr.mediaName} ({pr.n})
                  </span>
                </div>
                <div className="col-span-1 font-bold underline"></div>
                <div className="col-span-7 text-xs">
                  <pre
                    className={` whitespace-pre-wrap lng-${
                      pr.mediaLang || 'unknown'
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: highlighted,
                    }}
                  ></pre>
                  {pr.matches
                    ? pr.matches.map((m) => {
                        return (
                          <span className="font-bold" key={m.text}>
                            {m.text} ({m.indexes.length})&nbsp;
                          </span>
                        );
                      })
                    : undefined}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const Explore = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(ExploreComponent);
