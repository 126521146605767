import { Action } from '../models';

/*
  Actions and Action creators
*/

export const SAVE_FORR = 'SAVE_FORR';
export interface SaveForrPayload {
  forr: null | string;
}
export const saveForrAction = (payload: SaveForrPayload) => {
  return {
    type: SAVE_FORR,
    payload,
  } as Action;
};
