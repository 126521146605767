import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { Media } from '../models';
import { LinkToYoutube } from './utils/LinkToYoutube';
import { getWeeks } from './utils';
import { Pagination } from './utils/Pagination';

interface MediasProps {
  user: UserState['user'];
}
const MediasComponent = (props: MediasProps) => {
  const [currenPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number[] | undefined>(undefined);
  const [expr, setExpr] = useState<string | undefined>(undefined);
  const [account, setAccount] = useState<string>('');
  const [medias, setMedias] = useState<Media[]>([]);

  const loadMedias = async (skip: number) => {
    const resp = await fetch(`${API_BASE_URL}/medias-admin/?skip=${skip}`, {
      credentials: 'include',
    }).then((r) => r.json());

    setMedias(resp.medias);
    let pages = [];
    for (let i = 1; i < Math.ceil(resp.count / 50); i += 1) {
      pages.push(i);
    }
    setPages(pages);
  };

  useEffect(() => {
    if (props.user) {
      loadMedias(0);
    }
  }, []);

  const fullyear = new Date().getFullYear();

  return (
    <div>
      <h1 className="pb-4">Medias</h1>
      <div className="p-4 text-lg text-orange-800 bg-orange-100 mb-4">
        {medias.length === 0 && <p>No medias</p>}
        {medias.length === 1 && <p>One media</p>}
        {medias.length > 1 && <p>{medias.length} medias</p>}
      </div>
      <Pagination
        disabled={false}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadMedias(w * 50);
        }}
      />
      {medias.length > 0 && (
        <table className="table-auto">
          <thead className="">
            <tr>
              <th colSpan={1}>Date</th>
              <th colSpan={1}>Media</th>
              <th colSpan={1}>Matches</th>
            </tr>
          </thead>
          <tbody>
            {medias.map((media) => {
              const d = new Date(media.publishedAt);
              const today =
                new Date().getMonth() === d.getMonth() &&
                new Date().getUTCDate() === d.getUTCDate();

              let matches: { [a: string]: number } = {};
              (media.matches || []).forEach((ma) => {
                if (!matches[ma.text]) matches[ma.text] = 0;
                matches[ma.text] += ma.occurrences;
              });
              return (
                <tr>
                  <td className="text-xs" width="46px">
                    {today
                      ? 'today'
                      : new Intl.DateTimeFormat('en-GB', {
                          dateStyle: 'medium',
                        })
                          .format(new Date(media.publishedAt))
                          .replace(fullyear + '', '')}
                  </td>
                  <td className="">
                    <img
                      alt="youtube thumbnail"
                      src={media.thumbnail}
                      width="32px"
                      className="pr-2 inline"
                    />
                    {media.source === 'youtube' && (
                      <LinkToYoutube
                        classNames="text-xs inline"
                        text={media.name.slice(0, 60)}
                        n={0}
                        mediaShortCode={media.shortCode}
                      ></LinkToYoutube>
                    )}
                    {media.views} /{Math.floor(media.viewsPerHour)}
                  </td>
                  <td>
                    {Object.keys(matches).map((ma) => {
                      return (
                        <span
                          key={ma}
                          className="underline text-blue-800 text-xs mr-1"
                          onClick={() => {
                            if (expr === ma) {
                              setExpr(undefined);
                            } else {
                              setExpr(ma);
                            }
                          }}
                        >
                          {ma} ({matches[ma]})
                        </span>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Pagination
        disabled={false}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadMedias(w * 50);
        }}
      />
    </div>
  );
};

export const Medias = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(MediasComponent);
