import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Board, Group } from '../../models';
import { UserState } from '../../reducers/user';
import { getUser } from '../../selectors/user';
import { RootState } from '../../reducers';
import { HorizontalLine } from './HorizontalLineShort';
import { fullDate } from '../utils';
import { VideosManager } from './VideosManager';

interface BoardsGroupProps {
  user: UserState['user'];
  debug: boolean;
  group: Group;
  index: number;
}

const BoardsGroupComponent = (props: BoardsGroupProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {}, []);

  const horizontals = props.group.horizontals;
  if (props.group.verticals && props.group.verticals.length) {
    const left = props.index % 2 === 0 || [7, 11, 13].includes(props.index);
    return (
      <>
        <div className={`board-mixed-1 ${left ? 'left' : 'right'}`}>
          <VideosManager boards={props.group.verticals}></VideosManager>
          {horizontals[0] && (
            <HorizontalLine
              debug={props.debug}
              board={horizontals[0]}
              classNames="horizontal1 short"
              side={left ? 'left' : 'right'}
            ></HorizontalLine>
          )}
          {horizontals[1] && (
            <HorizontalLine
              debug={props.debug}
              board={horizontals[1]}
              classNames="horizontal2 short"
              side={left ? 'left' : 'right'}
            ></HorizontalLine>
          )}
          {horizontals[2] && (
            <HorizontalLine
              debug={props.debug}
              board={horizontals[2]}
              classNames="horizontal3 short"
              side={left ? 'left' : 'right'}
            ></HorizontalLine>
          )}
          {horizontals[3] && (
            <HorizontalLine
              debug={props.debug}
              board={horizontals[3]}
              classNames="horizontal4 short"
              side={left ? 'left' : 'right'}
            ></HorizontalLine>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {(props.group.horizontals[0] && props.group.horizontals[0].yesterday) ||
      (props.group.horizontals[1] && props.group.horizontals[1].yesterday) ? (
        <div className="date-line flex items-center font-bold text-sm">
          <p>Yesterday</p>
        </div>
      ) : undefined}
      {(props.group.horizontals[0] && props.group.horizontals[0].date) ||
      (props.group.horizontals[1] && props.group.horizontals[1].date) ? (
        <div className="date-line flex items-center font-bold text-sm">
          <p>
            {fullDate(
              (props.group.horizontals[0].date ||
                props.group.horizontals[1].date) as Date
            )}
          </p>
        </div>
      ) : undefined}
      <div className="board-horizontals-1">
        {horizontals[0] && (
          <HorizontalLine
            debug={props.debug}
            board={horizontals[0]}
            classNames="horizontal1 long"
          ></HorizontalLine>
        )}
        {horizontals[1] && (
          <HorizontalLine
            debug={props.debug}
            board={horizontals[1]}
            classNames="horizontal2 long"
          ></HorizontalLine>
        )}
      </div>
    </>
  );
};

export const BoardsGroup = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(BoardsGroupComponent);
