import isEqual from 'lodash.isequal';
import React, { useState } from 'react';

const Approved = () => (
  <img
    style={{ width: '28px', height: '28px' }}
    className="mr-1"
    src="/approved.png"
  ></img>
);
const Unchecked = () => (
  <img
    style={{ width: '28px', height: '28px' }}
    className="mr-1"
    src="/unchecked.png"
  ></img>
);
const Hint = (props: { text: string }) => <p className="hint">{props.text}</p>;

interface Option {
  value: any;
  label: any;
  hint?: string;
}
export const FunSelect = (props: {
  filtering: boolean;
  disabled: boolean;
  options: Option[];
  selectedOption: any;
  selectOption: (o: any) => void;
  setFiltering: (o: boolean) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const so = props.options.find(
    (o) =>
      o.value === props.selectedOption || isEqual(o.value, props.selectedOption)
  );

  if (expanded) {
    return (
      <div className="fun-select">
        {props.options.map((o) => {
          let Label = () => o.label;
          if (typeof o.label !== 'string') {
            Label = o.label;
          }
          return (
            <div
              key={o.label}
              className={`option ${props.disabled ? 'disabled' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                if (props.disabled) return;
                if (so && so.label === o.label && so.value === o.value) {
                  props.selectOption(undefined);
                } else {
                  props.selectOption(o.value);
                }
                // setExpanded(false);
                props.setFiltering(false);
              }}
            >
              <div className="in">
                {so && so.label === o.label ? <Approved /> : <Unchecked />}
                <Label></Label>
              </div>
              {o.hint ? <Hint text={o.hint} /> : undefined}
            </div>
          );
        })}
      </div>
    );
  }

  if (so) {
    return (
      <div className="fun-select">
        <div
          className={`option ${props.disabled ? 'disabled' : ''} ${
            props.filtering ? 'filtering' : ''
          }`}
          onClick={(e: any) => {
            e.preventDefault();
            if (props.disabled || props.filtering) return;
            // setExpanded(true);
            props.setFiltering(true);
          }}
        >
          <div className="in">
            <Approved />
            {(so as Option).label}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fun-select">
      <div
        className={`option ${props.disabled ? 'disabled' : ''} ${
          props.filtering ? 'filtering' : ''
        }`}
        onClick={(e: any) => {
          e.preventDefault();
          if (props.disabled || props.filtering) return;
          // setExpanded(true);
          props.setFiltering(true);
        }}
      >
        <Unchecked />
        Select option
      </div>
    </div>
  );
};
