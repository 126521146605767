import React, { useState } from 'react';

export const LinkToInstagram = (props: {
  mediaShortCode: string;
  accountName: string;
}) => {
  return (
    <a
      href={`https://www.instagram.com/p/${props.mediaShortCode}/`}
      target="_blank"
      className="font-bold"
      rel="noreferrer"
    >
      @{props.accountName}
    </a>
  );
};
