import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, connect } from 'react-redux';
import { Route } from 'wouter';
import mixpanel from 'mixpanel-browser';

import GlobalStyle from './GlobalStyle';
import store from './store';
import { User } from './models/';

import { Nav } from './components/Nav';
import { SignUp } from './components/SignUp';
import { Clusters } from './components/Clusters';
import { Explore } from './components/Explore';
import { Playground } from './components/Playground';
import { Stats } from './components/Stats';
import { LogIn } from './components/LogIn';
import { Jobs } from './components/Jobs';
import { UserBoard } from './components/UserBoard';
import { Account } from './components/Account';
import { Medias } from './components/Medias';
import { getMe } from './actions/user';
import { RootState } from './reducers';
import { getUser } from './selectors/user';
import { Matches } from './components/Matches';
import { UserMedias } from './components/UserMedias';
import { UserMyMedias } from './components/UserMyMedias';
import { API_BASE_URL } from './constants/api';
import { AdminBoards } from './components/AdminBoards';
import { Pool } from './components/Pool';
import { getForr } from './selectors/main';
import { SaveForrPayload, saveForrAction } from './actions/main';
import { getValidExprFromUrl } from './components/utils'

export const mp = {
  identify: (id: string) => {
    if (window.location.host === 'app.almostfriends.ai') {
      try {
        (window as any).mixpanel.identify(id);
      } catch (err) {
        console.log(err);
      }
    }
  },
  navigate: (properties?: { [a: string]: string | number }) => {
    if (window.location.host === 'app.almostfriends.ai') {
      try {
        (window as any).mixpanel.track('navigate', properties);
      } catch (err) {
        console.log(err);
      }
    }
  },
};
interface AppProps {
  getMe: () => void;
  saveForr: (forr: null | string) => void;
  user: User | undefined;
  forr: null | string;
}

class AppComponent extends React.Component<AppProps> {
  saveYoutubeToken = () => {
    const url = new URL((window as any).location);
    fetch(`${API_BASE_URL}/actions/youtube-save-token`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        code: url.searchParams.get('code'),
      }),
    })
      .then((r) => r.text())
      .then((resp) => {
        (window as any).location = '/account';
      })
      .catch((err) => {
        console.error('error youtube-save-token');
        console.error(err);
      });
  };

  componentDidMount() {
    this.props.getMe();

    /*
      after google.youtube auth, e are redirected to app/amostfriends.ai
    */
    const url = new URL((window as any).location);
    if (url.search.startsWith('?code=')) {
      this.saveYoutubeToken();
    }

    const urlParams = new URLSearchParams(window.location.search);
    let forr = urlParams.get('for');
    if (forr) {
      this.props.saveForr(decodeURIComponent(forr));
      mp.identify(forr);
    }
  }

  render() {
    if (getValidExprFromUrl() && !this.props.user) {
      return (
        <div className="container">
          <Nav></Nav>
          {/* user */}
          <Route path="/">{() => <UserBoard />}</Route>
          <Route path="/user-medias">
            {() => <UserMedias />}
          </Route>
        </div>
      );
    }

    if (!this.props.user) {
      return (
        <div className="container">
          <Nav></Nav>
          <Route path="/">{() => <LogIn></LogIn>}</Route>
          <Route path="/login">{() => <LogIn></LogIn>}</Route>
          <Route path="/signup">{() => <SignUp></SignUp>}</Route>
          <Route path="/board/:clusterId">
            {(params) => <Pool clusterId={params.clusterId} />}
          </Route>
        </div>
      );
    }

    return (
      <div className="container">
        <Nav></Nav>
        {/* user */}
        <Route path="/">{() => <UserBoard />}</Route>
        <Route path="/user-medias">
          {() => <UserMedias />}
        </Route>
        <Route path="/my-medias">{() => <UserMyMedias />}</Route>
        <Route path="/signup">{() => <SignUp />}</Route>
        <Route path="/login">{() => <LogIn />}</Route>
        <Route path="/account">{() => <Account />}</Route>
        {/* admin */}
        <Route path="/boards">{() => <AdminBoards clusterId={9} />}</Route>
        <Route path="/clusters">{() => <Clusters />}</Route>
        <Route path="/explore">{() => <Explore />}</Route>
        <Route path="/playground">{() => <Playground />}</Route>
        <Route path="/jobs">{() => <Jobs />}</Route>
        <Route path="/stats">{() => <Stats />}</Route>
        <Route path="/medias">{() => <Medias />}</Route>
        <Route path="/matches">{() => <Matches />}</Route>
        <Route path="/board/:clusterId">
          {(params) => <Pool clusterId={params.clusterId} />}
        </Route>
      </div>
    );
  }
}

const App = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
      forr: getForr(state),
    };
  },
  (dispatch) => ({
    getMe: () => dispatch(getMe() as any),
    saveForr: (forr: null | string) => dispatch(saveForrAction({ forr })),
  })
)(AppComponent);

const container = document.getElementById('root');
if (!container) {
  throw new Error('No container found');
}

document.addEventListener('DOMContentLoaded', (event) => {
  (window as any).mixpanel = mixpanel;
  (window as any).mp = mp;
  if (window.location.host === 'app.almostfriends.ai') {
    mixpanel.init('fb23e679a6340bc4d53d8ce60d54526f', {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
    mixpanel.set_config({ persistence: 'localStorage' });
    let mixpanelId = localStorage.getItem('mixpanelId');
    if (!mixpanelId) {
      let r = '';
      for (let i = 0; i < 12; i += 1) {
        r += Math.round(Math.random() * 10) + '';
      }
      mixpanelId = r;
      localStorage.setItem('mixpanelId', mixpanelId);
    }
    mp.identify(mixpanelId);
  }

  const root = createRoot(container);

  if (
    window.location.pathname.startsWith('/board') &&
    window.location.pathname.includes('11')
  ) {
    root.render(
      <Provider store={store}>
        <GlobalStyle />
        <Pool clusterId={'11'} />
      </Provider>
    );
    return;
  }

  if (
    window.location.host === 'goodwatch.es' ||
    (window.location.pathname.startsWith('/board') &&
      window.location.pathname.includes('16'))
  ) {
    root.render(
      <Provider store={store}>
        <GlobalStyle />
        <Pool clusterId={'16'} />
      </Provider>
    );
    return;
  }

  if (
    window.location.pathname.startsWith('/board') &&
    window.location.pathname.includes('9')
  ) {
    root.render(
      <Provider store={store}>
        <GlobalStyle />
        <Pool clusterId={'191'} />
      </Provider>
    );
    return;
  }

  root.render(
    <Provider store={store}>
      <GlobalStyle />
      <App store={store} />
    </Provider>
  );
});
