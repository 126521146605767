import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { Cluster } from '../models';
import { SelectedCluster } from './SelectedCluster';

interface ClustersProps {
  user: UserState['user'];
}
const ClustersComponent = (props: ClustersProps) => {
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [newClusterName, setNewClusterName] = useState<string>('');
  const [selectedCluster, setSelectedCluster] = useState<Cluster | undefined>(
    undefined
  );

  useEffect(() => {
    if (clusters.length) {
      return;
    }
    fetch(`${API_BASE_URL}/clusters`, { credentials: 'include' })
      .then((r) => r.json())
      .then((resp) => {
        setClusters(resp);
        if (selectedCluster) {
          setSelectedCluster(
            resp.find((c: any) => c.id === selectedCluster.id)
          );
        }
      });
  });

  return (
    <div>
      <h1 className="pb-4">Clusters</h1>
      <form className="pb-4">
        <div className="flex flex-row justify-start items-center">
          <label className="text-lg pr-4 font-bold">Nom du cluster</label>
          <input
            className="mr-4 input-normal"
            onChange={(e) => {
              setNewClusterName(e.target.value || '');
            }}
            placeholder="Mode, vêtements, chic, France"
            type="text"
            value={newClusterName}
          />

          <button
            className="btn"
            type="button"
            onClick={(e) => {
              fetch(`${API_BASE_URL}/actions/create-cluster`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  clusterLang: 'fr',
                  clusterName: newClusterName,
                }),
              })
                .then((r) => r.text())
                .then((resp) => {
                  setClusters([]);
                });
            }}
            disabled={
              !newClusterName ||
              !!clusters.find((c) => c.name === newClusterName)
            }
          >
            Créer le cluster
          </button>
        </div>
      </form>
      <div className="max-w-full pb-4 flex flex-wrap">
        {clusters.map((c) => {
          return (
            <div
              key={c.id}
              style={{ minWidth: '200px' }}
              className="cursor-pointer rounded bg-purple-100 hover:bg-purple-200 border-purple-200 border-2 p-2 mr-2 mb-2  text-purple-800 min-w-32"
              onClick={(e) => {
                e.preventDefault();
                if (selectedCluster && c.id === selectedCluster.id) {
                  // setSelectedCluster(undefined);
                } else {
                  setSelectedCluster(c);
                }
              }}
            >
              <h3 className="font-bold text-lg pb-2">{c.name}</h3>
              <p className="font-normal">{c.description}</p>
            </div>
          );
        })}
      </div>
      {!!selectedCluster && (
        <SelectedCluster
          key={selectedCluster.id}
          cluster={selectedCluster}
          reloadClusters={() => {
            setClusters([]);
          }}
        ></SelectedCluster>
      )}
    </div>
  );
};

export const Clusters = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(ClustersComponent);
