import React, { useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { Chunk } from '../models';
import { LinkToYoutube } from './utils/LinkToYoutube';

const types: { [a: string]: string } = {
  textTranscript: "Texte dans l'image",
  audioTranscript: 'Mention (audio)',
};

interface PlaygroundProps {
  user: UserState['user'];
}
const PlaygroundComponent = (props: PlaygroundProps) => {
  const [search, setSearch] = useState<string>('');
  const [chunks, setChunks] = useState<Chunk[]>([]);

  return (
    <div>
      <h1 className="pb-4">Playground</h1>
      <form className="pb-4">
        <div className="flex flex-row justify-start items-center">
          <label className="text-lg pr-4 font-bold">Rechercher un terme</label>
          <input
            className="mr-4 input-normal"
            onChange={(e) => {
              setSearch(e.target.value || '');
            }}
            placeholder="channel"
            type="text"
            value={search}
          />
          <button
            className="btn"
            type="button"
            onClick={() => {
              fetch(`${API_BASE_URL}/medias/?like=${search}`, {
                credentials: 'include',
              })
                .then((r) => r.json())
                .then((resp) => {
                  setChunks(resp);
                });
            }}
            disabled={!search}
          >
            Chercher
          </button>
        </div>
      </form>
      <div className="p8 font-bold text-lg">
        {chunks.length === 0 && <p>No results</p>}
        {chunks.length === 1 && <p>One result</p>}
        {chunks.length > 1 && <p>{chunks.length} results</p>}
      </div>
      <div>
        {chunks.map((pr) => {
          return (
            <div key={pr.id} className="flex justify-start grid grid-cols-12">
              <div className="col-span-3">
                {new Date(pr.mediaPublishedAt).toLocaleDateString()}{' '}
                {new Date(pr.mediaPublishedAt).toLocaleTimeString()}
              </div>
              <div className="col-span-3 font-bold underline">
                {pr.mediaSource === 'instagram' && (
                  <LinkToInstagram
                    mediaShortCode={pr.mediaShortCode}
                    accountName={pr.accountName}
                  ></LinkToInstagram>
                )}
                {pr.mediaSource === 'youtube' && (
                  <LinkToYoutube
                    text={`@${
                      pr.accountName.length > 30
                        ? `${pr.accountName.slice(0, 27)}…`
                        : pr.accountName
                    }`}
                    n={pr.n}
                    mediaShortCode={pr.mediaShortCode}
                  ></LinkToYoutube>
                )}
              </div>
              <div className="col-span-3 ">{types[pr.type]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Playground = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(PlaygroundComponent);

export const LinkToInstagram = (props: {
  mediaShortCode: string;
  accountName: string;
}) => {
  return (
    <a
      href={`https://www.instagram.com/p/${props.mediaShortCode}/`}
      target="_blank"
      className="font-bold"
      rel="noreferrer"
    >
      @{props.accountName}
    </a>
  );
};
