import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { API_BASE_URL } from '../constants/api';
import { saveUserAction } from '../actions/user';
import { Link, useLocation } from 'wouter';

interface NavProps {
  user: UserState['user'];
  saveUser: (user: UserState['user']) => void;
}
const NavComponent = (props: NavProps) => {
  const [location, navigate] = useLocation();

  const logout = () => {
    fetch(`${API_BASE_URL}/actions/log-out`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.text())
      .then((resp) => {
        props.saveUser(undefined);
        navigate(`/`);
      });
  };

  return (
    <nav className="grid grid-cols-12 mb-8">
      <div className="col-span-12 flex justify-between p-4 pl-0">
        <ul className="flex items-center">
          <li className="pr-4 flex items-center">
            <span className="fira text-2xl font-bold">Almost Friends</span>
            <span className="font-normal text-xs text-slate-700">
              &nbsp;&nbsp;&nbsp;&nbsp;beta
            </span>
          </li>
          {!props.user && (
            <>
              <li className="mr-6">
                <Link href="/">
                  <button
                    className={`text-sm  ${
                      ['/', ''].includes(location) ? 'font-bold' : ''
                    }`}
                  >
                    App
                  </button>
                </Link>
              </li>
              <li className="mr-6">
                <Link href="/user-medias">
                  <button
                    className={`text-sm  ${
                      ['/user-medias'].includes(location) ? 'font-bold' : ''
                    }`}
                  >
                    Medias
                  </button>
                </Link>
              </li>
            </>
          )}
          {props.user && (
            <>
              <li className="mr-6">
                <Link href="/">
                  <button
                    className={`text-sm  ${
                      ['/', ''].includes(location) ? 'font-bold' : ''
                    }`}
                  >
                    App
                  </button>
                </Link>
              </li>
              <li className="mr-6">
                <Link href="/user-medias">
                  <button
                    className={`text-sm  ${
                      ['/user-medias'].includes(location) ? 'font-bold' : ''
                    }`}
                  >
                    Medias
                  </button>
                </Link>
              </li>
              <li className="mr-6">
                <Link href="/my-medias">
                  <button
                    className={`text-sm  ${
                      ['/my-medias'].includes(location) ? 'font-bold' : ''
                    }`}
                  >
                    My medias
                  </button>
                </Link>
              </li>
            </>
          )}
          {props.user && props.user.admin && (
            <>
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/clusters`)}
                  className={`text-sm  ${
                    location === '/clusters' ? 'font-bold' : ''
                  }`}
                >
                  Clusters
                </button>
              </li>
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/boards`)}
                  className={`text-sm  ${
                    location === '/boards' ? 'font-bold' : ''
                  }`}
                >
                  Boards
                </button>
              </li>
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/playground`)}
                  className={`text-sm  ${
                    location === '/playground' ? 'font-bold' : ''
                  }`}
                >
                  Playground
                </button>
              </li>
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/explore`)}
                  className={`text-sm  ${
                    location === '/explore' ? 'font-bold' : ''
                  }`}
                >
                  Explore
                </button>
              </li>
              {/* <li className="mr-6">
                <button
                  onClick={() => navigate(`/medias`)}
                  className={`text-sm  ${
                    location === '/medias' ? 'font-bold' : ''
                  }`}
                >
                  Medias
                </button>
              </li> */}
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/stats`)}
                  className={`text-sm  ${
                    location === '/stats' ? 'font-bold' : ''
                  }`}
                >
                  Stats
                </button>
              </li>
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/matches`)}
                  className={`text-sm  ${
                    location === '/matches' ? 'font-bold' : ''
                  }`}
                >
                  Matches
                </button>
              </li>
              <li className="mr-6">
                <button
                  onClick={() => navigate(`/jobs`)}
                  className={`text-sm  ${
                    location === '/jobs' ? 'font-bold' : ''
                  }`}
                >
                  Jobs
                </button>
              </li>
            </>
          )}
        </ul>
        <ul className="flex items-center">
          {props.user ? (
            <li className="mr-6">
              <span
                style={{ maxWidth: '200px', textOverflow: 'ellipsis' }}
                className="font-bold"
              >
                {props.user.email}
              </span>
            </li>
          ) : undefined}
          {props.user ? (
            <button
              className={`text-sm ${
                location === '/account' ? 'font-bold' : ''
              }`}
              onClick={() => navigate(`/account`)}
            >
              Account
            </button>
          ) : undefined}
          <li className="mr-6">
            {props.user ? undefined : (
              <button
                className={`text-sm ${
                  location === '/signup' ? 'font-bold' : ''
                }`}
                onClick={() => navigate(`/signup`)}
              >
                sign up
              </button>
            )}
          </li>
          <li className="mr-6">
            {props.user ? (
              <button
                className={`text-sm`}
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                Log out
              </button>
            ) : (
              <Link href="/login">
                <button
                  className={`text-sm ${
                    location === '/login' ? 'font-bold' : ''
                  }`}
                >
                  log in
                </button>
              </Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export const Nav = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({
    saveUser: (user: UserState['user']) => {
      dispatch(saveUserAction({ user: user }));
    },
  })
)(NavComponent);
