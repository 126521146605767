import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { getUser } from '../selectors/user';
import { Board, Media } from '../models';
import { UserState } from '../reducers/user';
import { fullDate, getWeeks } from './utils';
import { LineMyMedia } from './utils/LineMyMedia';
import { MediaAndComments } from './MediaAndComments';
import { Pagination } from './utils/Pagination';

interface AdminBoardsProps {
  user: UserState['user'];
  clusterId: number;
}

const AdminBoardsComponent = (props: AdminBoardsProps) => {
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<[string, string, string] | undefined>(
    undefined
  );
  const [pages, setPages] = useState<number[] | undefined>(undefined);
  const [currenPage, setCurrentPage] = useState<number>(1);
  const [boards, setBoards] = useState<Board[]>([]);

  const loadBoards = (skip: number) => {
    setLoading(true);
    fetch(`${API_BASE_URL}/boards?clusterId=${props.clusterId}`, {
      credentials: 'include',
    })
      .then((r) => r.json())
      .then((res) => {
        setLoading(false);
        console.log(res);
        setBoards(res.boards);
        let pages = [];
        for (let i = 1; i < Math.ceil(res.count / 50); i += 1) {
          pages.push(i);
        }
        setPages(pages);
      })
      .catch((er) => {
        console.error(er);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadBoards(0);
  }, []);

  if (selectedMedia) {
    return (
      <MediaAndComments
        media={selectedMedia}
        onBack={() => setSelectedMedia(undefined)}
      />
    );
  }

  return (
    <div>
      <Pagination
        disabled={loading}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadBoards(w * 50);
        }}
      />
      <div className="p-4 text-lg text-orange-900 bg-orange-100 mb-2">
        {boards.length === 0 && <p>No results</p>}
        {boards.length === 1 && <p>One result</p>}
        {boards.length > 1 && <p>{boards.length} results</p>}
      </div>
      <table className="table-auto">
        <thead>
          <tr>
            <th className="text-sm">date</th>
            <th className="text-sm">name</th>
            <th className="text-sm">note</th>
            <th className="text-sm">views per hour</th>
            <th className="text-sm">- (calc)</th>
            <th className="text-sm">likes per hour</th>
            <th className="text-sm">- (calc)</th>
            <th className="text-sm">hours elapsed (calc)</th>
            <th className="text-sm">threshold</th>
            <th className="text-sm">threshold ajuster</th>
          </tr>
        </thead>
        <tbody>
          {boards.map((board) => {
            return (
              <tr>
                <td>{fullDate(new Date(board.from))}</td>
                <td>{board.name}</td>
                <td>
                  <b>{board.notation}</b>
                </td>
                <td>{board.viewsPerHour}</td>
                <td>{board.calcViewsPerHour}</td>
                <td>{board.likesPerHour}</td>
                <td>{board.calcLikesPerHour}</td>
                <td>{board.calcHoursElapsed}</td>
                <td>{board.threshold}</td>
                <td>
                  {board.thresholdAjusted && board.thresholdAjusted > 0
                    ? `+${board.thresholdAjusted}`
                    : board.thresholdAjusted}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        disabled={loading}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadBoards(w * 50);
        }}
      />
    </div>
  );
};

export const AdminBoards = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(AdminBoardsComponent);
