import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { getDefaultClusterId, getUser } from '../selectors/user';
import { Media } from '../models';
import { LineMedia } from './utils/LineMedia';
import { UserState } from '../reducers/user';
import { getWeeks } from './utils';
import { FunSelect } from './utils/FunSelect';

interface UserMediasProps {
  user: UserState['user'];
  defaultClusterId: undefined | number;
}

const UserMediasComponent = (props: UserMediasProps) => {
  const [weeks, setWeeks] = useState<[string, string, string][]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filtering, setFiltering] = useState<boolean>(true);
  const [alsoBlogs, setAlsoBlogs] = useState<boolean>(true);
  const [alsoYoutubeVideos, setAlsoYoutubeVideos] = useState<boolean>(true);
  const [date, setDate] = useState<[string, string, string] | undefined>(
    undefined
  );
  const [medias, setMedias] = useState<Media[]>([]);
  const [clustersId, setClustersId] = useState<string[]>([]);
  const [selectedClusterId, setSelectedClusterId] = useState<null | string>(
    null
  );

  const loadMedias = (
    d: [string, string, string] | undefined,
    ab: boolean,
    ayv: boolean,
    clusterId: null | string
  ) => {
    setLoading(true);
    let typeOfMedia = [];
    if (ab) typeOfMedia.push('article');
    if (ayv) typeOfMedia.push('video');

    /* let clusterId: number | string = props.forr
      ? props.forr.split('-')[1]
      : '16';
    if (props.defaultClusterId) clusterId = props.defaultClusterId; */

    fetch(
      `${API_BASE_URL}/medias?clusterId=${clusterId}&typeOfMedia=${JSON.stringify(
        typeOfMedia
      )}${d ? `&date=${d[0]}TO${d[1]}` : ''}`,
      { credentials: 'include' }
    )
      .then((r) => r.json())
      .then((res) => {
        setLoading(false);
        setMedias(res.medias);
      })
      .catch((er) => {
        console.error(er);
        setLoading(false);
      });
  };

  useEffect(() => {
    const w = getWeeks();
    let currentDate = w[0];
    setDate(currentDate);
    setWeeks(w.reverse());

    let clustersId: { [a: string]: true } = {};
    if (props.user) {
      props.user.brands.forEach((brand) => {
        clustersId[brand.cluster] = true;
      });
    }
    setClustersId(Object.keys(clustersId));
    setSelectedClusterId(Object.keys(clustersId)[0] || null);

    if (props.user) {
      loadMedias(
        currentDate,
        alsoBlogs,
        alsoYoutubeVideos,
        Object.keys(clustersId)[0] || null
      );
    }
  }, []);

  return (
    <div>
      <div className="pb-4 flex flex flex-wrap items-center justify-start">
        {clustersId.map((cid) => {
          return (
            <div key={cid}>
              <a
                className={`text-xl text-black pt-4 pb-2 pr-2 flex items-center cursor-pointer ${
                  cid === selectedClusterId ? 'font-bold' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedClusterId(cid);
                  loadMedias(date, alsoBlogs, alsoYoutubeVideos, cid);
                }}
              >
                {
                  {
                    9: '🚲 Biking',
                    11: '⛺ Hiking',
                    12: '💻 Gaming',
                    13: '🌐 SAAS',
                    15: '💄 Care',
                    16: '⌚ Luxury Watches',
                    18: '🧑🏻‍🌾 Agri FR',
                    19: '🧑🏻‍🌾 Agri EN',
                  }[cid]
                }
              </a>
            </div>
          );
        })}
      </div>
      <div className="flex items-center pb-2">
        <FunSelect
          filtering={filtering}
          setFiltering={setFiltering}
          disabled={loading}
          options={[
            {
              label: () => (
                <span className="flex items-center">
                  <img src="/earth.png" width="20px" height="auto"></img>
                  &nbsp;Blogs
                </span>
              ),
              value: true,
            },
          ]}
          selectOption={(so) => {
            if (alsoBlogs !== so) {
              setAlsoBlogs(so);
              loadMedias(date, so, alsoYoutubeVideos, selectedClusterId);
            }
          }}
          selectedOption={alsoBlogs}
        />
        <FunSelect
          filtering={filtering}
          setFiltering={setFiltering}
          disabled={loading}
          options={[
            {
              label: () => (
                <span className="flex items-center">
                  <img src="/youtube.png" width="20px" height="auto"></img>
                  &nbsp;Youtube videos
                </span>
              ),
              value: true,
            },
          ]}
          selectOption={(so) => {
            if (alsoYoutubeVideos !== so) {
              setAlsoYoutubeVideos(so);
              loadMedias(date, alsoBlogs, so, selectedClusterId);
            }
          }}
          selectedOption={alsoYoutubeVideos}
        />
      </div>
      <div className="p-4 text-lg text-orange-900 bg-orange-100 mb-2">
        {medias.length === 0 && <p>No results</p>}
        {medias.length === 1 && <p>One result</p>}
        {medias.length > 1 && <p>{medias.length} results</p>}
      </div>
      <div className="flex justify-start mb-4">
        {weeks.map((w) => {
          return (
            <button
              key={w[2]}
              className={`mr-2 text-sm p-1 bg-gray-100 ${
                date && date[0] === w[0] ? 'font-bold' : ''
              }`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setDate(w);
                loadMedias(w, alsoBlogs, alsoYoutubeVideos, selectedClusterId);
              }}
            >
              {w[2]}
            </button>
          );
        })}
      </div>
      {medias.length > 0 && (
        <div>
          <div className="flex justify-start grid grid-cols-12">
            <div className="col-span-1"></div>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="col-span-5"></div>
          </div>
          {medias.map((media) => {
            return (
              <LineMedia key={`media-${media.id}`} media={media}></LineMedia>
            );
          })}
        </div>
      )}{' '}
      <div className="flex justify-start">
        {weeks.map((w) => {
          return (
            <button
              key={w[2]}
              className={`mr-2 text-sm p-1 bg-gray-100 ${
                date && date[0] === w[0] ? 'font-bold' : ''
              }`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setDate(w);
                loadMedias(w, alsoBlogs, alsoYoutubeVideos, selectedClusterId);
              }}
            >
              {w[2]}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export const UserMedias = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
      defaultClusterId: getDefaultClusterId(state),
    };
  },
  (dispatch) => ({})
)(UserMediasComponent);
