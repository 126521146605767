import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { getUser } from '../selectors/user';
import { Media } from '../models';
import { UserState } from '../reducers/user';
import { getWeeks } from './utils';
import { LineMyMedia } from './utils/LineMyMedia';
import { MediaAndComments } from './MediaAndComments';
import { Pagination } from './utils/Pagination';

interface UserMyMediasProps {
  user: UserState['user'];
}

const UserMyMediasComponent = (props: UserMyMediasProps) => {
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<[string, string, string] | undefined>(
    undefined
  );
  const [pages, setPages] = useState<number[] | undefined>(undefined);
  const [currenPage, setCurrentPage] = useState<number>(1);
  const [medias, setMedias] = useState<Media[]>([]);

  const loadMedias = (skip: number) => {
    setLoading(true);
    fetch(
      `${API_BASE_URL}/my-medias?typeOfMedia=["video"]&skip=${skip}&accountId=my`,
      {
        credentials: 'include',
      }
    )
      .then((r) => r.json())
      .then((res) => {
        setLoading(false);
        setMedias(res.medias);
        let pages = [];
        for (let i = 1; i < Math.ceil(res.count / 50); i += 1) {
          pages.push(i);
        }
        setPages(pages);
      })
      .catch((er) => {
        console.error(er);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.user) {
      loadMedias(0);
    }
  }, []);

  if (selectedMedia) {
    return (
      <MediaAndComments
        media={selectedMedia}
        onBack={() => setSelectedMedia(undefined)}
      />
    );
  }

  return (
    <div>
      <div className="p-4 text-lg text-orange-900 bg-orange-100 mb-2">
        {medias.length === 0 && <p>No results</p>}
        {medias.length === 1 && <p>One result</p>}
        {medias.length > 1 && <p>{medias.length} results</p>}
      </div>
      <Pagination
        disabled={loading}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadMedias(w * 50);
        }}
      />
      {medias.length > 0 && (
        <div>
          <div className="flex justify-start grid grid-cols-12">
            <div className="col-span-1"></div>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="col-span-5"></div>
          </div>
          {medias.map((media) => {
            return (
              <LineMyMedia
                key={`media-${media.id}`}
                media={media}
                onClick={() => {
                  setSelectedMedia(media);
                }}
              ></LineMyMedia>
            );
          })}
        </div>
      )}{' '}
      <Pagination
        disabled={loading}
        pages={pages || []}
        currenPage={currenPage}
        onClick={(w) => {
          setCurrentPage(w);
          loadMedias(w * 50);
        }}
      />
    </div>
  );
};

export const UserMyMedias = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(UserMyMediasComponent);
