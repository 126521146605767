import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { User } from '../models';
import { getMe } from '../actions/user';
import { RootState } from '../reducers';
import { getUser } from '../selectors/user';

interface SelectedClusterProps {
  user: User | undefined;
  getMe: () => void;
}
const AccountComponent = (props: SelectedClusterProps) => {
  const [getYoutubeLinkError, setGetYoutubeLinkError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [revoking, setRevoking] = useState<boolean>(false);

  const revokeYoutube = () => {
    if (!props.user) return;
    if (loading) return;
    setGetYoutubeLinkError('');
    setLoading(true);
    fetch(`${API_BASE_URL}/actions/youtube-revoke`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((r) => r.text())
      .then((resp) => {
        setLoading(false);
        props.getMe();
      })
      .catch((err) => {
        setGetYoutubeLinkError(
          'Something went wrong (1), please contact administrator'
        );
        console.error('error youtube-revoke');
        console.error(err);
        setLoading(false);
      });
  };

  const linkYoutube = () => {
    if (!props.user) return;
    if (loading) return;
    setGetYoutubeLinkError('');
    setLoading(true);
    fetch(`${API_BASE_URL}/actions/youtube-get-link`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((r) => r.text())
      .then((resp) => {
        setLoading(false);
        if (resp.startsWith('https://accounts.google.com')) {
          (window as any).location = resp;
        } else {
          setGetYoutubeLinkError(
            'Redirect link is not accounts.google.com, please contact administrator'
          );
        }
      })
      .catch((err) => {
        setGetYoutubeLinkError(
          'Something went wrong (1), please contact administrator'
        );
        console.error('error youtube-get-link');
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.user) {
    }
  }, []);

  return (
    <div>
      <h2 className="pb-4">Account</h2>
      <form>
        <div className="grid grid-cols-12 pb-8">
          <div className="col-span-9">
            <label className="block text-normal font-medium leading-6 text-gray-900 flex items-center pb-2">
              <img
                className="pr-2"
                src="/youtube.png"
                width="30px"
                height="auto"
              ></img>
              Youtube
            </label>
            <p className="text-sm pb-2">
              Linking your Youtube account to almost friends allows us to get
              the metadata associated with your videos and comments, and post or
              reply to comments.
            </p>
            {props.user && props.user.youtubeUserId && (
              <div className="flex flex-col">
                <p className="flex items-center">
                  <img
                    className="pr-1"
                    src="/checked.png"
                    width="20px"
                    height="auto"
                  ></img>
                  Youtube account&nbsp;
                  <a
                    className="font-bold underline"
                    href={`https://www.youtube.com/channel/${props.user.youtubeUserId}`}
                  >
                    {props.user.youtubeUserId}
                  </a>
                  &nbsp; linked to Almost Friends &nbsp;
                </p>

                {revoking ? (
                  <p className="flex items-center">
                    Are you sure you want to revoke Youtube ? &nbsp;
                    <a
                      className="font-bold underline pr-1 cursor-pointer"
                      onClick={revokeYoutube}
                    >
                      Yes
                    </a>
                    <a
                      className="underline pr-1 cursor-pointer"
                      onClick={() => {
                        setRevoking(false);
                      }}
                    >
                      Cancel
                    </a>
                  </p>
                ) : (
                  <a
                    className="font-bold underline pr-1 cursor-pointer"
                    onClick={() => {
                      setRevoking(true);
                    }}
                  >
                    Revoke Youtube link
                  </a>
                )}
              </div>
            )}
            {props.user && !props.user.youtubeUserId && (
              <>
                <div className="mt-2 w-full">
                  <a
                    type="button"
                    className={`underline font-bold text-lg ${
                      loading
                        ? 'cursor-default text-gray-600'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      linkYoutube();
                    }}
                  >
                    Link my Youtube account
                  </a>
                </div>
                {getYoutubeLinkError && (
                  <p className="text-red-300 pt-2 pb-2">
                    {getYoutubeLinkError}
                  </p>
                )}
              </>
            )}
          </div>
          <div className="col-span-3"></div>
          <div className="col-span-9 mt-8">
            <label className="block text-normal font-medium leading-6 text-gray-900 flex items-center">
              <img
                className="pr-2"
                src="/instagram.png"
                width="30px"
                height="auto"
              ></img>
              Instagram
            </label>
            <div className="mt-2 w-full"></div>
          </div>
          <div className="col-span-3"></div>
        </div>
      </form>
    </div>
  );
};

export const Account = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({
    getMe: () => {
      dispatch(getMe() as any);
    },
  })
)(AccountComponent);
