import { Board } from '../../models';

const s = 1000;
const min = s * 60;
const hour = min * 60;

export const timeAgo = (board: Board) => {
  const from = new Date(board.from).getTime();
  const now = new Date().getTime();
  if (now - from < min) {
    return ['selected one minute ago 🔥🔥🔥', 'date-very-hot'];
  } else if (now - from < 3 * min) {
    return ['selected less than three minutes ago 🔥🔥🔥', 'date-very-hot'];
  } else if (now - from < 30 * min) {
    const m = Math.floor((now - from) / 1000 / 60);
    return [`selected ${m} minutes ago 🔥🔥🔥`, 'date-very-hot'];
  } else if (now - from < 60 * min) {
    return ['selected about an hour ago', 'date-hot'];
  } else if (now - from < 120 * min) {
    return ['selected about two hours ago', 'date-hot'];
  } else if (now - from < 180 * min) {
    return ['selected about three hours ago', 'date-normal'];
  } else if (now - from < 240 * min) {
    return ['selected about four hours ago', 'date-normal'];
  } else if (now - from < 24 * hour) {
    const h = Math.floor((now - from) / 1000 / 60 / 60);
    return [`selected ${h} hours ago`, 'date-normal'];
  }
  return ['selected more than a day ago', 'date-normal'];
};
