import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  .container {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-top: 2rem;
    padding: 0px 1rem;
  }

  .bg-black-grad {
    color: #fff;
    background: linear-gradient(45deg, #000, #114);
  }
  .bg-black-grad.disabled {
    cursor: not-allowed;
    color: #777;
    background: linear-gradient(45deg, rgb(182 183 162), rgb(182 187 154));
  }
  .bg-black-grad:hover:not(.disabled) {
    color: #fff;
    background: linear-gradient(45deg, #222, #336);
  }
`;

export default GlobalStyle;
