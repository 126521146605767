import React from 'react';
import 'intersection-observer';
import { connect } from 'react-redux';

import { RootState } from '../../reducers';
import { getUser } from '../../selectors/user';
import { Account, Media } from '../../models';
import { displayBigNumber } from '../utils';

interface MediaBlockSmallProps {
  accountName: Account['name'];
  followers: Account['followers'];
  source: Media['source'];
  type: Media['type'];
  views: Media['views'];
}
const MediaBlockSmallComponent = (props: MediaBlockSmallProps) => {
  return (
    <div className="flex items-center">
      {props.type === 'article' ? (
        <div className="flex items-center">
          {props.accountName.length > 30
            ? `${props.accountName.slice(0, 27)}…`
            : props.accountName}
          &nbsp;
        </div>
      ) : undefined}
      {props.source === 'youtube' && props.accountName ? (
        <div className="flex items-center">
          {props.accountName.length > 30
            ? `${props.accountName.slice(0, 27)}…`
            : props.accountName}
          &nbsp;
          <span
            className="text-sm font-extrabold flex justify-center items-center m-0 p-1 bg-gray-100 rounded border-gray-300"
            title={`${displayBigNumber(props.followers)} followers`}
          >
            {displayBigNumber(props.followers)}
            &nbsp;
            <img
              src="/person.png"
              width="16px"
              alt="Follower on social media"
            ></img>
            &nbsp;
          </span>
        </div>
      ) : undefined}
      {props.source === 'instagram' && props.accountName ? (
        <div className="flex items-center">
          <img
            className="pr-1"
            src="/instagram.png"
            width="20px"
            height="auto"
          ></img>
          @{props.accountName || ''}
          &nbsp;
          <span
            className="text-sm font-extrabold flex justify-center items-center m-0 p-1 bg-gray-100 rounded border-gray-300"
            title={`${displayBigNumber(props.followers)} followers`}
          >
            {displayBigNumber(props.followers)}
            &nbsp;
            <img
              src="/person.png"
              width="16px"
              height="16px"
              alt="Follower on social media"
            ></img>
            &nbsp;
          </span>
        </div>
      ) : undefined}
      {['youtube', 'instagram'].includes(props.source) ? (
        <div className="flex items-center">
          <div className="flex pr-4">
            <p style={{}} className="text-gray-500 text-sm font-normal">
              {displayBigNumber(props.views)} views
            </p>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export const MediaBlockSmall = MediaBlockSmallComponent;
