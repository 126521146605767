import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

import { API_BASE_URL } from '../constants/api';
import { RootState } from '../reducers';
import { Expression, CommentTs } from '../models';
import { UserState } from '../reducers/user';
import { getValidExprFromUrl } from './utils'

import { getUser } from '../selectors/user';
import {
  getTypesenseClient,
  getWeeks,
  loadAllCommentsTypesense,
} from './utils';
import { FunSelect } from './utils/FunSelect';
import { FunSelectMulti } from './utils/FunSelectMulti';
import {
  SearchResponse,
  SearchResponseHit,
} from 'typesense/lib/Typesense/Documents';
import { ChunkTs } from '../models/ChunkTs';
import { LineChunkTs } from './utils/LineChunkTs';
import { LineCommentTs } from './utils/LineCommentTs';
import { ResultsCount } from './utils/ResultsCount';
import { LineDate } from './utils/LineDate';

interface UserBoardProps {
  user: UserState['user'];
}

const defaultResultsCount = {
  youtube: 0,
  instagram: 0,
  instagramComments: 0,
  articles: 0,
  youtubeComments: 0,
};

const UserBoardComponent = (props: UserBoardProps) => {
  const [weeks, setWeeks] = useState<[string, string, string][]>(
    getWeeks().reverse()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [filtering, setFiltering] = useState<boolean>(false);
  const [date, setDate] = useState<[string, string, string]>(getWeeks()[0]);
  const [expressionsGrouped, setExpressionsGrouped] = useState<{
    [a: string]: Expression[];
  }>({});
  const [expr, setExpr] = useState<string | undefined>(undefined);
  const [alsoComments, setAlsoComments] = useState<0 | 1>(1);
  const [typeOfChunk, setTypeOfChunk] = useState<string[]>(['audioTranscript']);
  const [resultsCount, setResultCount] = useState<{ [a: string]: number }>(
    defaultResultsCount
  );
  const [tsInstances, setTsInstances] = useState<
    (
      | {
          chunk: {
            group_key: string[];
            hits: SearchResponseHit<ChunkTs>[];
            found?: number;
          };
        }
      | {
          comment: {
            document: CommentTs;
            highlights: SearchResponseHit<CommentTs>['highlights'];
          };
        }
    )[]
  >([]);
  const [displayTexts, setDisplayTexts] = useState<boolean>(true);

  useEffect(() => {
    const resultsCount: { [a: string]: number } = {
      youtube: 0,
      instagram: 0,
      instagramComments: 0,
      articles: 0,
      youtubeComments: 0,
    };
    let typeFilter = `type:=[${typeOfChunk
      .map((toc) => `'${toc}'`)
      .join(',')}]`;
    setTsInstances([]);
    if (!expr) return;
    setLoading(true);
    setResultCount(defaultResultsCount);
    const client = getTypesenseClient();
    client
      .collections('chunks')
      .documents()
      .search({
        q: '',
        query_by: 'mediaName',
        sort_by: 'publishedAt:desc',
        facet_by: `matches.${expr}`,
        num_typos: 0,
        page: 1,

        filter_by: [
          `publishedAt:>${new Date(date[0]).getTime()}`,
          `publishedAt:<${new Date(date[1]).getTime()}`,
          typeFilter,
          `matches.${expr}:>=1`,
        ].join(' && '),
        group_by: 'media',
      })
      .then((chunksLoaded: SearchResponse<object>) => {
        const chunkInstances = (chunksLoaded.grouped_hits || []).map((gh) => {
          const doc = gh.hits[0].document as any;
          resultsCount.youtube += doc.mediaSource === 'youtube' ? 1 : 0;
          resultsCount.instagram += doc.mediaSource === 'instagram' ? 1 : 0;
          resultsCount.articles += doc.type === 'article' ? 1 : 0;
          return {
            chunk: gh as {
              group_key: string[];
              hits: SearchResponseHit<ChunkTs>[];
              found?: number;
            },
          };
        });
        if (alsoComments === 1) {
          loadAllCommentsTypesense(client, {
            q: '',
            query_by: `text`,
            sort_by: 'publishedAt:desc',
            num_typos: 0,
            facet_by: `matches.${expr}`,
            filter_by: [
              `publishedAt:>${new Date(date[0]).getTime()}`,
              `publishedAt:<${new Date(date[1]).getTime()}`,
              `matches.${expr}:>=1`,
            ].join(' && '),
          }).then((commentsLoaded: SearchResponseHit<CommentTs>[]) => {
            setTsInstances(
              (chunkInstances as any)
                .concat(
                  commentsLoaded.map((hit) => {
                    resultsCount.youtubeComments += !!hit.document.youtubeUserId
                      ? 1
                      : 0;
                    resultsCount.instagramComments += !!hit.document
                      .instagramUserId
                      ? 1
                      : 0;
                    return {
                      comment: {
                        document: hit.document,
                        highlights: hit.highlights,
                      } as {
                        document: CommentTs;
                        highlights: SearchResponseHit<CommentTs>['highlights'];
                      },
                    };
                  })
                )
                .sort((a: any, b: any) => {
                  let p1 = undefined;
                  if (a.chunk) p1 = a.chunk.hits[0].document.publishedAt;
                  if (a.comment) p1 = a.comment.document.publishedAt;
                  let p2 = undefined;
                  if (b.chunk) p2 = b.chunk.hits[0].document.publishedAt;
                  if (b.comment) p2 = b.comment.document.publishedAt;
                  return p1 < p2 ? 1 : -1;
                })
            );
            setLoading(false);
            setResultCount(resultsCount);
          });
        } else {
          setResultCount(resultsCount);
          setTsInstances(chunkInstances);
          setLoading(false);
        }
      })
      .catch((er) => {
        console.error(er);
        setLoading(false);
      });
  }, [alsoComments, typeOfChunk, expr, date]);

  useEffect(() => {
    const expressionId = getValidExprFromUrl() && (getValidExprFromUrl() as any)[1];
    if (expressionId) {
      fetch(`${API_BASE_URL}/expression-by-id?expressionId=${expressionId}`, {
        credentials: 'include',
      })
        .then((r) => r.json())
        .then((res) => {
          if (res.expression) {
            setExpr(res.expression.text);
            setExpressionsGrouped({
              [res.expression.cluster]: [
                { text: res.expression.text },
              ] as Expression[],
            });
          }
        })
        .catch((er) => {
          console.error(er);
        });
      return;
    }
    if (props.user) {
      setExpr(props.user.brands[0].text);
      const grouped: { [a: string]: Expression[] } = {};
      props.user.brands.forEach((expression) => {
        if (!grouped[expression.cluster]) grouped[expression.cluster] = [];
        grouped[expression.cluster].push(expression);
        setExpressionsGrouped(grouped);
      });
      if (props.user.brands[0]) {
        setExpr(props.user.brands[0].text);
      }
    }
  }, []);

  let lastPublishedAt: string = '2024-02-01';

  return (
    <div>
      <form className="pb-4">
        <div className="pb-4 flex flex flex-wrap items-center justify-start">
          {Object.keys(expressionsGrouped).map((clusterId) => {
            return (
              <div key={clusterId}>
                <h4
                  key={clusterId}
                  className={`text-2xl font-bold text-black pt-4 pb-2 flex items-center`}
                >
                  {
                    {
                      9: '🚲 Biking',
                      11: '⛺ Hiking',
                      12: '💻 Gaming',
                      13: '🌐 SAAS',
                      15: '💄 Care',
                      16: '⌚ Luxury Watches',
                      18: '🧑🏻‍🌾 Agri FR',
                      19: '🧑🏻‍🌾 Agri EN',
                    }[clusterId]
                  }
                </h4>
                {expressionsGrouped[clusterId].map((ex) => {
                  return (
                    <button
                      className={`text-lg text-black ${
                        expr === ex.text ? 'font-bold underline' : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setExpr(ex.text);
                      }}
                    >
                      {expr === ex.text ? '✓' : ''}
                      &nbsp;
                      {ex.text}
                      &nbsp;
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </form>
      <div
        className="flex justify-start grid grid-cols-12 pb-4"
        style={{ height: 'auto' }}
      >
        <div className="col-span-12 flex justify-start items-start ">
          <div style={{ borderRight: '1px solid #888' }} className="pr-2">
            <h4 className="text-2xl">Source type</h4>
            <FunSelectMulti
              filtering={filtering}
              setFiltering={setFiltering}
              disabled={loading}
              options={[
                {
                  label:
                    'Match in the audio of the video <img class="pl-1" src="/youtube.png" width="20px" height="auto"></img><img class="pl-1" src="/instagram.png" width="20px" height="auto"></img>',
                  value: 'audioTranscript',
                  hint: 'Look for medias that have matches in the audio transcript of videos (Youtube and Instagram)',
                },
                {
                  label:
                    'Match in text, description or title of the video or post <img class="pl-1" src="/youtube.png" width="20px" height="auto"></img><img class="pl-1" src="/instagram.png" width="20px" height="auto"></img>',
                  hint: 'Look for medias with matches in the description or title (Youtube and Instagram)',
                  value: 'description',
                },
                {
                  label:
                    'Match in blog article <img class="pl-1" src="/earth.png" width="20px" height="auto"></img>',
                  hint: 'Look for matches in web articles',
                  value: 'article',
                },
              ]}
              selectOptions={(selectedOptions) => {
                if (!isEqual(selectedOptions, typeOfChunk)) {
                  setTypeOfChunk(selectedOptions);
                }
              }}
              selectedOptions={typeOfChunk}
            />
          </div>
          {/* <FunSelect
            filtering={filtering}
            setFiltering={setFiltering}
            disabled={loading}
            options={[
              {
                label: 'Only medias with matches',
                hint: 'Do not load any additional medias',
                value: 0,
              },
              {
                label: 'All medias',
                hint: 'Load all medias in the cluster',
                value: 1,
              },
            ]}
            selectOption={(so) => {
              if (alsoMedias !== so) {
                loadChunks(expr, date, so, alsoComments, typeOfChunk);
                setAlsoMedias(so);
              }
            }}
            selectedOption={alsoMedias}
          /> */}
          <div style={{ borderLeft: '0px solid #888' }} className="pl-2">
            <h4 className="text-2xl">Comments</h4>
            <FunSelect
              filtering={filtering}
              setFiltering={setFiltering}
              disabled={loading}
              options={[
                { label: 'All comments with a match', value: 1 },
                {
                  label: 'Only comments from matched medias',
                  value: 0,
                },
              ]}
              selectOption={(so) => {
                if (alsoComments !== so) {
                  setAlsoComments(so);
                }
              }}
              selectedOption={alsoComments}
            />
          </div>
        </div>
      </div>
      <div className="text-lg">
        <FunSelectMulti
          filtering={false}
          disabled={false}
          options={[
            {
              value: 'displayTexts',
              label: 'Display texts directly',
            },
          ]}
          selectedOptions={displayTexts ? ['displayTexts'] : []}
          selectOptions={(o) => {
            setDisplayTexts(!displayTexts);
            console.log(o);
          }}
          setFiltering={() => {}}
        ></FunSelectMulti>
        <ResultsCount
          youtube={resultsCount.youtube}
          youtubeComments={resultsCount.youtubeComments}
          instagram={resultsCount.instagram}
          instagramComments={resultsCount.instagramComments}
          articles={resultsCount.articles}
        />
      </div>
      <div className="flex justify-start mb-4">
        {weeks.map((w) => {
          return (
            <button
              key={w[2]}
              className={`mr-2 text-sm p-1 bg-gray-100 ${
                date && date[0] === w[0] ? 'font-bold' : ''
              }`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setDate(w);
              }}
            >
              {w[2]}
            </button>
          );
        })}
      </div>
      {tsInstances.length > 0 && (
        <div>
          <div className="flex justify-start grid grid-cols-12">
            <div className="col-span-1"></div>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="col-span-5"></div>
          </div>
          {tsInstances.map((instance, i) => {
            if (instance.hasOwnProperty('chunk')) {
              const instanceChunk = (instance as any).chunk;
              let NewDay = () => <div></div>;
              const pa = new Date(instanceChunk.hits[0].document.publishedAt)
                .toISOString()
                .slice(0, 10);
              console.log(pa);
              if (
                new Date(lastPublishedAt).getTime() < new Date(pa).getTime()
              ) {
                console.warn('bad sorting', lastPublishedAt, pa);
              }
              if (lastPublishedAt !== pa || i === 0) {
                lastPublishedAt = pa;
                NewDay = () => <LineDate date={pa}></LineDate>;
              }
              return (
                <>
                  <NewDay key={lastPublishedAt} />
                  <LineChunkTs
                    key={`chunk-${instanceChunk.hits[0].document.type}-${expr}-${instanceChunk.hits[0].document.id}`}
                    expr={expr as string}
                    date={date}
                    chunk={instanceChunk}
                    displayCommentsByDefault={true}
                  ></LineChunkTs>
                </>
              );
            }
            if (instance.hasOwnProperty('comment')) {
              const instanceComment = (instance as any).comment;
              const pa = new Date(instanceComment.document.publishedAt)
                .toISOString()
                .slice(0, 10);
              console.log(pa);
              let NewDay = () => <div></div>;
              if (
                new Date(lastPublishedAt).getTime() < new Date(pa).getTime()
              ) {
                console.warn('bad sorting', lastPublishedAt, pa);
              }
              if (lastPublishedAt > pa || i === 0) {
                lastPublishedAt = pa;
                NewDay = () => <LineDate date={pa}></LineDate>;
              }
              return (
                <>
                  <NewDay key={lastPublishedAt} />
                  <LineCommentTs
                    user={props.user}
                    key={`comment-${expr}-${instanceComment.document.id}`}
                    expr={expr as string}
                    comment={instanceComment}
                    displayText={displayTexts}
                  ></LineCommentTs>
                </>
              );
            }

            return <div></div>;
          })}
        </div>
      )}
      <div className="flex justify-start">
        {weeks.map((w) => {
          return (
            <button
              key={w[2]}
              className={`mr-2 text-sm p-1 bg-gray-100 ${
                date && date[0] === w[0] ? 'font-bold' : ''
              }`}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setDate(w);
              }}
            >
              {w[2]}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export const UserBoard = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(UserBoardComponent);
