import React, { useState } from 'react';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../constants/api';

import { RootState } from '../reducers';
import { UserState } from '../reducers/user';
import { getUser } from '../selectors/user';
import { getMe } from '../actions/user';
import { useLocation } from 'wouter';
import { getTypesenseClient } from './utils';

interface LogInProps {
  user: UserState['user'];
  getMe: () => void;
}
const LogInComponent = (props: LogInProps) => {
  const [location, navigate] = useLocation();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const disabled =
    typeof email !== 'string' ||
    typeof password !== 'string' ||
    !password.length ||
    !email.length;

  return (
    <div className="flex justify-center">
      <div className="max-w-2xl">
        <h1>Log in</h1>
        <br />
        <form>
          <div className="pb-4">
            <label className="" htmlFor="email">
              Email address
            </label>
            <input
              className="input-big"
              id="email"
              type="text"
              placeholder="Email address"
              onChange={(e) => {
                const m = (e.target.value || '').match(
                  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
                );
                setServerError('');
                if (m && m[0] && m[0].length === e.target.value.length) {
                  setEmail(e.target.value);
                  setEmailError('');
                } else {
                  setEmail('');
                  setEmailError('Invalid email address');
                }
              }}
            ></input>
            {emailError && <p className="mt-1 font-bold error">{emailError}</p>}
          </div>
          <div className="pb-8">
            <label className="" htmlFor="password">
              Password
            </label>
            <input
              className={`input-big ${password.length ? 'border-success' : ''}`}
              id="password"
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                setServerError('');
              }}
            ></input>
          </div>
          {serverError && (
            <p className="mt-1 mb-2 font-bold error">{serverError}</p>
          )}
          <div className="pb-4">
            {disabled ? (
              <div className={`above-btn blue big disabled`}>
                <button disabled className={``} type="button">
                  Log in
                </button>
              </div>
            ) : (
              <div className={`above-btn blue big`}>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    let ok = false;
                    fetch(`${API_BASE_URL}/actions/log-in`, {
                      method: 'POST',
                      credentials: 'include',
                      body: JSON.stringify({
                        email: email,
                        password: password,
                      }),
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    })
                      .then((r) => {
                        if (r.ok) {
                          ok = true;
                          return r.text();
                        }
                        return r.text();
                      })
                      .then((resp) => {
                        if (ok) {
                          props.getMe();
                          navigate('/');
                        } else {
                          setServerError(resp as string);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                        setServerError(
                          'Network error, please contact the admin of the website'
                        );
                      });
                  }}
                  className={``}
                >
                  Log in
                </button>
              </div>
            )}
          </div>
        </form>
        <div></div>
      </div>
    </div>
  );
};

export const LogIn = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({
    getMe: () => {
      dispatch(getMe() as any);
    },
  })
)(LogInComponent);
