import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../reducers';
import { getUser } from '../../selectors/user';
import { Media, User } from '../../models';
import { hrefBasedOnShortCode } from '../utils';
import { MediaBlockSmall } from './MediaBlockSmall';
import { NewCommentYoutube } from './NewCommentYoutube';

interface LineMediaProps {
  user: undefined | User;
  media: Media;
}
const LineMediaComponent = (props: LineMediaProps) => {
  const divRef = useRef(null);
  useEffect(() => {}, []);
  const d = new Date(props.media.publishedAt);
  const today =
    new Date().getMonth() === d.getMonth() &&
    new Date().getUTCDate() === d.getUTCDate();

  const fullyear = new Date().getFullYear();

  let viewsPerHour = props.media.viewsPerHour;
  if (props.media.viewsPerHour !== 0) {
    viewsPerHour = Math.floor(viewsPerHour);
  }

  return (
    <>
      <div
        key={`${props.media.id}-`}
        className="flex justify-start grid grid-cols-12 pb-8"
        ref={divRef}
      >
        <div className="col-span-4 flex items-center justify-start relative">
          <div className="absolute" style={{ top: '6px', left: '-70px' }}>
            <span className="">
              {today
                ? 'today'.padEnd(12, ' ')
                : new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' })
                    .format(new Date(props.media.publishedAt))
                    .replace(fullyear + '', '')
                    .padEnd(12, ' ')}
            </span>
          </div>
          {props.media.source === 'youtube' ? (
            <>
              <img
                className="pr-1 pl-1"
                src="/youtube.png"
                width="30px"
                height="auto"
              ></img>
            </>
          ) : undefined}
          {props.media.source === 'instagram' ? (
            <>
              <img
                className="pr-1 pl-1"
                src="/instagram.png"
                width="30px"
                height="auto"
              ></img>
            </>
          ) : undefined}
          {props.media.type === 'article' ? (
            <>
              <img
                className="pr-1 pl-1"
                src="/earth.png"
                width="30px"
                height="auto"
              ></img>
            </>
          ) : undefined}
          <MediaBlockSmall
            views={props.media.views}
            source={props.media.source}
            type={props.media.type}
            followers={props.media.accountFollowers}
            accountName={props.media.accountName || props.media.source}
          />
        </div>
        <div className="col-span-8 flex items-center justify-start relative">
          {props.media.thumbnail ? (
            <img
              alt="youtube thumbnail"
              src={props.media.thumbnail}
              style={{ width: 'auto', height: '40px' }}
              width="auto"
              height="40px"
            />
          ) : undefined}
          <a
            className="font-bold rounded pl-2 px-3 text-sm flex justify-center items-start"
            href={hrefBasedOnShortCode(
              props.media.shortCode,
              props.media.source,
              props.media.type
            )}
            target="_blank"
          >
            <i>{`${
              props.media.name.length > 70
                ? `${props.media.name.slice(0, 70)}…`
                : props.media.name
            }`}</i>
            &nbsp;
          </a>
        </div>
        {props.media.source === 'youtube' && (
          <div className="col-span-12 grid grid-cols-12 pt-2">
            <div className="col-span-4"></div>
            <div className="col-span-8">
              <NewCommentYoutube
                user={props.user}
                videoId={props.media.shortCode}
              ></NewCommentYoutube>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const LineMedia = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(LineMediaComponent);
