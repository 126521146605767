import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Board, Group } from '../../models';
import { UserState } from '../../reducers/user';
import { getUser } from '../../selectors/user';
import { RootState } from '../../reducers';
import { border } from 'polished';
import { timeAgo } from './timeAgo';
import { mp } from '../../index';

interface HorizontalLineProps {
  debug?: boolean;
  board: Board;
  classNames: string;
  side?: 'left' | 'right';
}

const images: { [a: string]: number } = {
  SamPilgrim: 1,
  bikeradar: 1,
  tristantakevideo: 1,
  gcn: 2,
  gcntech: 1,
  gmbn: 3,
  PathLessPedaledTV: 1,
  NorCalCycling: 1,
  BermPeakExpress: 1,
  Cade_Media: 2,
  JeffKendallWeed: 1,
  chainreactioncycles: 1,
  RomanSharf: 1,
  JourdainColeman: 1,
  GCPerformance18: 1,
  TraceVelo: 1,

  ChrisMillerCycling: 1,
  cyclingweekly: 1,

  // watch
  ProducerMichael: 1,
  watchgringa: 1,
  Timeandtidewatches: 1,
  thehonestwatchdealer: 1,
  CasualWatchReviews: 1,
  JustOneMoreWatch: 1,
  thetimeteller: 1,
  NicoLeonard: 1,
  FedericoTalksWatches: 1,
  watchfinder: 1,
  AndrewMorganWatches: 1,
  TheoandHarris: 2,
  TeddyBaldassarre: 1,
  chronographbyeffin: 1,
  theurbangentry: 1,
  JenniElle: 1,
  WatchChris: 1,
  WatchEric: 1,
  RevolutionWatch: 1,
  LuxuryBazaar: 1,
  OracleTimeTV: 1,
  Crmjewelers: 1,
  chronographbyeffinm397: 1,
  wornandwound: 2,
  ChisholmHunterOfficial: 1,
  thatwatchguylondon: 1,

  // hiking
  ChaseMountains: 1,
  BackpackingTV: 1,
  PaulMessner: 1,
  DanBecker: 1,
  MyLifeOutdoors: 1,
  WildBeare: 1,
  TAOutdoors: 1,
  HazeOutdoors: 1,
  BackcountryExposure: 1,
  BackpackingUK: 1,
  KyleHatesHiking: 1,
  HomemadeWanderlust: 1,
  GearPriority: 1,
  JustinOutdoors: 1,
  ASTHECROWFLIESHIKING: 1,
  ScottysGoneWalkabout: 1,
};

const HorizontalLineComponent = (props: HorizontalLineProps) => {
  useEffect(() => {}, []);

  return (
    <div
      className={`horizontal relative ${props.side === 'left' ? 'pl-2' : ''} ${
        props.board.additionalClasses || ''
      } ${props.classNames}`}
      style={{}}
    >
      {images[props.board.accountName] === 1 ? (
        <img
          className="pr-2 funnyface1-1"
          title={`${props.board.accountName} channel on youtube`}
          src={`/youtubers/${props.board.accountName}1.png`}
          width="42px"
          height="auto"
        ></img>
      ) : null}
      {images[props.board.accountName] === 2 ? (
        <>
          <img
            className="pr-0 funnyface2-1"
            title={`${props.board.accountName} channel on youtube`}
            src={`/youtubers/${props.board.accountName}1.png`}
            width="42px"
            height="auto"
          ></img>
          <img
            className="pr-2 funnyface2-2"
            title={`${props.board.accountName} channel on youtube`}
            src={`/youtubers/${props.board.accountName}2.png`}
            width="42px"
            height="auto"
          ></img>
        </>
      ) : null}
      {images[props.board.accountName] === 3 ? (
        <>
          <img
            className="pr-0 funnyface3-1"
            title={`${props.board.accountName} channel on youtube`}
            src={`/youtubers/${props.board.accountName}1.png`}
            width="42px"
            height="auto"
          ></img>
          <img
            className="pr-0 funnyface3-2"
            title={`${props.board.accountName} channel on youtube`}
            src={`/youtubers/${props.board.accountName}2.png`}
            width="42px"
            height="auto"
          ></img>
          <img
            className="pr-1 funnyface3-3"
            title={`${props.board.accountName} channel on youtube`}
            src={`/youtubers/${props.board.accountName}3.png`}
            width="42px"
            height="auto"
          ></img>
        </>
      ) : null}
      {/* <span className="views flex items-center">
        <span className="font-bold text-normal">
          {Math.round(props.board.viewsPerHour)}
        </span>
        <span className="font-bold text-sm pl-1">per hour</span>
      </span> */}
      <a
        target="_blank"
        onClick={() => {
          mp.navigate({ url: props.board.mediaHref });
        }}
        href={props.board.mediaHref}
      >
        {true ? (
          <span className="flex items-center">
            <img
              className="pr-1"
              src="/youtube.png"
              width="20px"
              height="auto"
            ></img>
            <span style={{ color: '#323232' }} className="youtube-name pr-1">
              @{props.board.accountName}
            </span>
            <span className={timeAgo(props.board)[1]}>
              {timeAgo(props.board)[0]}
            </span>
          </span>
        ) : undefined}
        <p
          className={`media-name ${
            props.board.name.length > 70
              ? 'text-sm sm:text-base md:text-base lg:text-base xl:text-base '
              : 'text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl'
          } font-medium`}
        >
          {props.board.name}
        </p>
      </a>
      {props.debug ? (
        <span className="debug-from">from {props.board.from.slice(0, 16)}</span>
      ) : null}
      {props.debug ? (
        <span className="debug-until">to {props.board.until.slice(0, 16)}</span>
      ) : null}
      {props.debug ? (
        <span className="debug-notation">
          notation {Math.round(100 * props.board.notation) / 100}
        </span>
      ) : null}
    </div>
  );
};

export const HorizontalLine = HorizontalLineComponent;
