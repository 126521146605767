import React, { ReactElement } from 'react';

import { Comment, CommentTs, Media } from '../../models';
import { highlightWords2 } from '../utils';

interface CommentSmallProps {
  comment: Comment | CommentTs;
  highlightedTranscript?: string;
  classNames: string;
  children: ReactElement | null;
}
export const CommentSmall = (props: CommentSmallProps) => {
  return (
    <div className={props.classNames}>
      <div>{props.children}</div>
      <p className="comment relative">
        <span
          dangerouslySetInnerHTML={{
            __html: props.highlightedTranscript || props.comment.text,

            /* highlightWords2(
              props.comment.text,
              (props.comment.indexes || []).map((ind: any) => {
                return [ind.location, 'linear-gradient(45deg, #eaa, #faf);'];
              })
            ), */
          }}
        ></span>
        <span
          className="flex flex-col justify-start absolute"
          style={{ top: '0px', right: '-32px' }}
        >
          {props.comment.hearted && (
            <img
              className="mb-2"
              title="The channel owner loved (heart) the comment"
              style={{ width: '16px', height: '16px' }}
              src={'/heart.png'}
            ></img>
          )}
          {props.comment.liked && (
            <img
              className="mb-2"
              title="The channel owner liked the comment"
              style={{ width: '16px', height: '16px' }}
              src={'/thumbsup.png'}
            ></img>
          )}
          {props.comment.disliked && (
            <img
              className="mb-2"
              title="The channel owner disliked the comment"
              style={{ width: '16px', height: '16px' }}
              src={'/thumbsdown.png'}
            ></img>
          )}
          {props.comment.likeCount !== 0 ? (
            <span
              className="pb-2 text-gray-400 text-sm flex inline items-center justify-center"
              title={`${props.comment.likeCount} likes on the comment`}
            >
              <img
                style={{
                  width: '16px',
                  height: '16px',
                  filter: 'grayscale(60%)',
                }}
                src={'/thumbsup.png'}
              ></img>
              &nbsp;
              {props.comment.likeCount}
            </span>
          ) : undefined}
        </span>
      </p>
    </div>
  );
};
