import { SAVE_USER, SaveUserPayload } from '../actions/user';
import { Action, User } from '../models/';

export interface UserState {
  user: undefined | User;
}
const initialState: UserState = {
  user: undefined,
};

export default function user(
  state: UserState = initialState,
  action: Action
): UserState {
  if (window.location.href.includes('localhost')) {
    console.log(
      '%cuser',
      'font-weight: bold; color: #FFF;padding:2px;background:#111;',
      action
    );
  }
  switch (action.type) {
    case SAVE_USER: {
      const payload: SaveUserPayload = action.payload;
      return {
        ...state,
        user: payload.user,
      };
    }

    default:
      return state;
  }
}
