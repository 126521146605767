import React from 'react';

interface ResultsCountProps {
  youtube: number;
  youtubeComments: number;
  instagram: number;
  instagramComments: number;
  articles: number;
}
const ResultsCountComponent = (props: ResultsCountProps) => {
  let total = 0;
  total +=
    props.youtube +
    props.instagram +
    props.instagramComments +
    props.youtubeComments +
    props.articles;
  return (
    <p className="text-normal flex items-center results pt-8 pb-8">
      {total !== 0 && (
        <p className="flex whitespace-pre items-center pr-2">
          {total}
          &nbsp;results
        </p>
      )}
      {props.youtube !== 0 && (
        <p className="flex whitespace-pre items-center pr-2">
          <img
            src="/youtube.png"
            style={{ height: '15px', width: 'auto' }}
          ></img>
          &nbsp;
          {props.youtube}
          &nbsp;youtube medias
        </p>
      )}
      {props.instagram !== 0 && (
        <p className="flex whitespace-pre items-center pr-2">
          <img
            src="/instagram.png"
            style={{ height: '15px', width: 'auto' }}
          ></img>
          &nbsp;
          {props.instagram}
          &nbsp;instagram medias
        </p>
      )}
      {props.articles !== 0 && (
        <p className="flex whitespace-pre items-center pr-2">
          <img src="/earth.png" style={{ height: '15px', width: 'auto' }}></img>
          &nbsp;
          {props.articles}
          &nbsp;blog articles
        </p>
      )}
      {props.youtubeComments || props.instagramComments ? (
        <p className=" pr-2">&nbsp;|&nbsp;</p>
      ) : (
        ''
      )}
      {props.youtubeComments !== 0 && (
        <p className="flex items-center  pr-2">
          <img
            src="/youtube.png"
            style={{ height: '15px', width: 'auto' }}
          ></img>
          &nbsp;
          {props.youtubeComments}
          &nbsp;comments
        </p>
      )}
      {props.instagramComments !== 0 && (
        <p className="flex items-center">
          <img
            src="/instagram.png"
            style={{ height: '15px', width: 'auto' }}
          ></img>
          &nbsp;
          {props.instagramComments}
          &nbsp;comments
        </p>
      )}
    </p>
  );
};

export const ResultsCount = ResultsCountComponent;
