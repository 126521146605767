import React, { useEffect, useRef, useState } from 'react';
import 'intersection-observer';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../constants/api';

import { RootState } from '../../reducers';
import { getUser } from '../../selectors/user';
import { Comment, CommentTs, Media, User } from '../../models';
import { highlightWords2, hrefBasedOnShortCode } from '../utils';
import { LinkToYoutube } from './LinkToYoutube';
import { CommentSmall } from './CommentSmall';
import { MediaBlockSmall } from '../utils/MediaBlockSmall';
import { NewCommentYoutube } from './NewCommentYoutube';
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents';
import { getMediaLoader } from './mediaLoader';
import { LinkToInstagram } from './LinkToInstagram';

interface LineCommentTsProps {
  user: undefined | User;
  comment: {
    document: CommentTs;
    highlights: SearchResponseHit<CommentTs>['highlights'];
  };
  expr: string;
  displayText: boolean;
}
const LineCommentTsComponent = (props: LineCommentTsProps) => {
  const [media, setMedia] = useState<Media | undefined>(undefined);

  useEffect(() => {
    const mediaLoader = getMediaLoader();
    mediaLoader.load(props.comment.document.media).then(setMedia);
    return;
  }, []);

  const divRef = useRef(null);
  const comment = props.comment.document;
  const d = new Date(comment.publishedAt);
  const today =
    new Date().getMonth() === d.getMonth() &&
    new Date().getUTCDate() === d.getUTCDate();
  const fullyear = new Date().getFullYear();

  let highlightedTranscript2 = ' ' + comment.text;
  if ((props.comment.document as any).fullMatches) {
    const fms = JSON.parse((props.comment.document as any).fullMatches);
    highlightedTranscript2 = highlightWords2(
      highlightedTranscript2,
      fms
        .filter((fm: any) => fm[0].text === props.expr)
        .map((fm: any) => fm.concat(['linear-gradient(45deg, #eaa, #faf);']))
    );
  }

  return (
    <div key={`${comment.media}-${comment.id}`} className="pb-2">
      <div
        className="flex justify-start grid grid-cols-12 relative"
        ref={divRef}
      >
        <div className="col-span-12 text-base flex flex-row justify-start">
          <div className="flex items-center">
            <img
              className="pr-1"
              title="Comment posted below the video or post"
              src="/at.png"
              width="20px"
              height="auto"
            ></img>
            {comment.youtubeName && (
              <img
                className="pr-1"
                src="/youtube.png"
                width="20px"
                height="auto"
              ></img>
            )}
            {comment.instagramName && (
              <img
                className="pr-1"
                src="/instagram.png"
                width="20px"
                height="auto"
              ></img>
            )}
            {media && comment.instagramName && (
              <LinkToInstagram
                accountName={comment.instagramName}
                mediaShortCode={media.shortCode}
              />
            )}
            {media && comment.youtubeName && (
              <LinkToYoutube
                text={comment.youtubeName}
                n={0}
                mediaShortCode={media.shortCode}
                additionalParams={`&lc=${comment.commentId}`}
                classNames={`text-sm font-bold pr-1`}
              ></LinkToYoutube>
            )}
          </div>
          <div className="flex items-center">
            {media && (
              <a
                className="font-bold rounded text-sm pr-2"
                href={hrefBasedOnShortCode(
                  media.shortCode,
                  media.source,
                  'audioTranscript'
                )}
                target="_blank"
              >
                <i>{`${
                  media.name.length > 70
                    ? `${media.name.slice(0, 70)}…`
                    : media.name
                }`}</i>
                &nbsp;
              </a>
            )}
            {media && (
              <MediaBlockSmall
                views={media?.views}
                source={media.source}
                type={media?.type}
                followers={media.accountFollowers}
                accountName={''}
              />
            )}
          </div>
          {/* media && media.source === 'youtube' && (
            <div className="flex items-center">
              &nbsp;
              <span className="text-emerald-500 font-bold rounded px-3 text-sm flex justify-center items-center">
                mentionned&nbsp;
                <i>{`"${props.expr}"`}</i>
              </span>
              &nbsp;
            </div>
          ) */}
        </div>
        <div className="col-span-3 flex items-center relative"></div>
        {props.displayText ? (
          <div className="col-span-12 grid grid-cols-12 pt-2">
            <div className="col-span-9">
              <CommentSmall
                comment={comment}
                highlightedTranscript={highlightedTranscript2}
                classNames="relative"
                children={null}
              ></CommentSmall>
            </div>
            <div className="col-span-3"></div>
          </div>
        ) : undefined}
        {/* <div className="col-span-12 grid grid-cols-12 pt-2">
          <div className="col-span-3"></div>
          <div className="col-span-9">
            <NewCommentYoutube
              user={props.user}
              comment={props.comment}
            ></NewCommentYoutube>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const LineCommentTs = LineCommentTsComponent;
