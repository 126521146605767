import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  createVerticalGroups,
  groupsInPool,
  isSameDay,
  isYesterday,
} from './utils/groupsInBoard';
import { RootState } from '../reducers';
import { getUser } from '../selectors/user';
import { Media, Comment, Group, Board } from '../models';
import { UserState } from '../reducers/user';
import { BoardsGroup } from './utils/BoardGroup';
import { API_BASE_URL } from '../constants/api';

interface PoolProps {
  user: UserState['user'];
  clusterId: string;
}

const PoolComponent = (props: PoolProps) => {
  const [groups, setGroups] = useState<Group[]>([]);

  const debug = window.location.href.includes('?debug');
  useEffect(() => {
    fetch(`${API_BASE_URL}/boards?clusterId=${props.clusterId}`, {
      credentials: 'include',
    })
      .then((r) => r.json())
      .then((resp) => {
        const verticalGroups = createVerticalGroups(resp.boards);
        const groups = groupsInPool(resp.boards, verticalGroups);
        setGroups(groups);
      });
  }, []);

  let yesterday = false;
  let lastDay: undefined | Date = undefined;

  return (
    <div
      className={`flex flex-col items-center justify-start board pb-16 cluster${props.clusterId}`}
    >
      {props.clusterId === '11' ? (
        <div className="header p-4 mb-8 flex flex-col items-center mt-4">
          <h1 className="font-bold text-4xl pb-8">Hiking life</h1>
          <p className="font-bold text-2xl pb-8 text-center">
            Everything hiking, trekking gear and equipement. New video content
            everyday.⛰️⛺🌄
          </p>
        </div>
      ) : null}

      {props.clusterId === '16' ? (
        <div className="header p-4 mb-8 flex flex-col items-center mt-4">
          <h1 className="font-bold text-4xl pb-8">Good Watches</h1>
          <p className=" text-2xl pb-8 text-center">
            High quality and trending luxury watches video content, everyday !
            ⌚✨
          </p>
        </div>
      ) : null}
      <div className="pool">
        <div className="date-line flex items-center font-bold text-sm">
          <p>Today</p>
        </div>
        {groups.map((group, i) => {
          console.log(i);
          if (
            !group.tagged &&
            group.horizontals.length === 2 &&
            groups[i + 1] &&
            groups[i + 2] &&
            groups[i + 1].horizontals.length === 2 &&
            groups[i + 2].horizontals.length === 2 &&
            ![2, 4].includes(i)
          ) {
            groups[i + 1].tagged = 'color';
            group.horizontals[1].additionalClasses = 'a-bit-lighter';
            groups[i + 1].horizontals[0].additionalClasses = 'lighter';
            groups[i + 1].horizontals[1].additionalClasses = 'a-bit-lighter';
            groups[i + 2].horizontals[0].additionalClasses =
              'a-tiny-bit-lighter';
            console.log(groups[i + 1]);
          }
          if (i !== 0 && !yesterday) {
            if (
              group.horizontals[0] &&
              isYesterday(new Date(group.horizontals[0].from))
            ) {
              yesterday = true;
              group.horizontals[0].yesterday = true;
            } else if (
              group.horizontals[1] &&
              isYesterday(new Date(group.horizontals[1].from))
            ) {
              yesterday = true;
              group.horizontals[1].yesterday = true;
            }
          }
          if (lastDay && group.horizontals.length) {
            const sameDay = isSameDay(
              new Date(group.horizontals[group.horizontals.length - 1].from),
              lastDay
            );
            if (sameDay !== true) {
              // do nothing
            }
          }
          if (group.horizontals.length) {
            lastDay = new Date(
              group.horizontals[group.horizontals.length - 1].from
            );
          }
          return (
            <BoardsGroup
              index={i}
              debug={debug}
              group={group}
              key={
                group.horizontals[0]
                  ? group.horizontals[0].from
                  : (group.verticals as Board[])[0].from
              }
            ></BoardsGroup>
          );
        })}
      </div>
    </div>
  );
};

export const Pool = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(PoolComponent);
