import React, { useState, useEffect, useRef, Fragment } from 'react';
import 'intersection-observer';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../constants/api';

import { RootState } from '../../reducers';
import { getUser } from '../../selectors/user';
import { Chunk, Comment, Media, User } from '../../models';
import { formatTranscriptHtml, fullDate, highlightWords2 } from '../utils';
import { LinkToYoutube } from './LinkToYoutube';
import { CommentSmall } from './CommentSmall';
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents';
import { ChunkTs } from '../../models/ChunkTs';
import { MediaBlock2 } from './MediaBlock2';
import { getMediaLoader } from './mediaLoader';

export const loadCommentsOfSameMediaAndExpr = (
  text: string,
  mediaId: number,
  cb: (resp: any) => void
) => {
  fetch(
    `${API_BASE_URL}/comments/?expressions=["${text}"]&mediaId=${mediaId}`,
    { credentials: 'include' }
  )
    .then((r) => r.json())
    .then((resp) => {
      cb(resp);
    });
};

interface LineChunkTsProps {
  user: undefined | User;
  chunk: {
    group_key: string[];
    hits: SearchResponseHit<ChunkTs>[];
    found?: number;
  };
  /* comments: Comment[]; */
  expr: string;
  date: undefined | [string, string, string];
  displayCommentsByDefault: boolean;
}
const LineChunkTsComponent = (props: LineChunkTsProps) => {
  const divRef = useRef(null);
  const [commentsLoaded, setCommentsLoaded] = useState<boolean>(false);
  const [displayComments, setDisplayComments] = useState<string | undefined>(
    undefined
  );
  const [comments, setComments] = useState<{
    [text: string]: {
      comments: Comment[];
      count: number;
    };
  }>({});
  const [otherChunk, setOtherChunk] = useState<
    SearchResponseHit<ChunkTs> | undefined
  >(undefined);
  const [displayText, setDisplayText] = useState<boolean>(false);
  const [otherChunks, setOtherChunks] = useState<{ [chunkId: string]: Chunk }>(
    {}
  );
  const [indexes, setIndexes] = useState<
    { chunkId: number; location: [number, number] }[]
  >([]);
  const [media, setMedia] = useState<Media | undefined>(undefined);

  useEffect(() => {
    const mediaLoader = getMediaLoader();
    mediaLoader.load(props.chunk.hits[0].document.media).then(setMedia);
    return;
  }, []);
  /* const loadChunksOfSameMedia = (typeOfChunk: string) => {
    if (props.chunk.type !== 'audioTranscript') return;
    fetch(
      `${API_BASE_URL}/chunks2/?expressions=["${props.expr.toLowerCase()}"]&typeOfChunk=["${typeOfChunk}"]&groupByMediaId=false&onlyChunksFromMediaId=${
        props.chunk.media
      }&alsoMedias=[]&alsoComments=[]${
        props.date ? `&date=${props.date[0]}TO${props.date[1]}` : ''
      }`,
      { credentials: 'include' }
    )
      .then((r) => r.json())
      .then((resp) => {
        const otherChunks: { [chunkId: string]: Chunk } = {};
        const indexes: { chunkId: number; location: [number, number] }[] = [];
        resp.instances.forEach((instance: any) => {
          if (instance.chunk) {
            const chunk: Chunk = instance.chunk;
            otherChunks[chunk.id] = chunk;
            instance.chunk.indexes.forEach((index: any) => {
              if (
                !indexes.find(
                  (ind) =>
                    ind.chunkId === chunk.id &&
                    ind.location[0] === index.location[0]
                )
              ) {
                indexes.push({
                  chunkId: chunk.id,
                  location: index.location,
                });
              }
            });
          }
        });

        setIndexes(indexes);
        setOtherChunks(otherChunks);
      }); 
  };*/

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (commentsLoaded) return;
          setCommentsLoaded(true);
          /* loadCommentsOfSameMediaAndExpr(
            props.expr.toLowerCase(),
            props.chunk.media,
            setComments
          ); */
          if (props.displayCommentsByDefault) {
            setDisplayComments(props.expr.toLowerCase());
          }
        }
      });
    });
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  const chunkIds: { [a: string]: true } = {};
  props.chunk.hits.forEach((h) => {
    chunkIds[h.document.id] = true;
  });
  const firstHit = props.chunk.hits[0];
  const currentHit = otherChunk || firstHit;

  const d = new Date(firstHit.document.publishedAt);
  const today =
    new Date().getMonth() === d.getMonth() &&
    new Date().getUTCDate() === d.getUTCDate();

  const fullyear = new Date().getFullYear();

  //const highlightedTranscript = (currentHit.highlight.data as any).snippet;
  let highlightedTranscript2 = currentHit.document.data;

  if ((currentHit.document as any).fullMatches) {
    const fms = JSON.parse((currentHit.document as any).fullMatches);
    highlightedTranscript2 = highlightWords2(
      highlightedTranscript2,
      fms
        .filter((fm: any) => fm[0].text === props.expr)
        .map((fm: any) => fm.concat(['linear-gradient(45deg, #eaa, #faf);']))
    );
  }

  let formattedTranscript = highlightedTranscript2;

  /*
    For articles, the title/name of the media
    has been put in the chunk text
  */
  if (currentHit.document.type === 'article') {
    if (formattedTranscript.indexOf(',,') !== -1) {
      formattedTranscript = formattedTranscript.slice(
        formattedTranscript.indexOf(',,') + 2
      );
    }
  }
  if (
    currentHit.document.type === 'audioTranscript' &&
    highlightedTranscript2.startsWith('[')
  ) {
    const timeRangeStart = parseInt(
      currentHit.document.timeRange.split('-')[0],
      10
    );
    formattedTranscript = formatTranscriptHtml(
      highlightedTranscript2,
      timeRangeStart,
      currentHit.document.mediaShortCode
    );
  }
  return (
    <div key={`${firstHit.document.media}-${currentHit.document.n}`}>
      <div className="flex justify-start grid grid-cols-12" ref={divRef}>
        <div className="col-span-12">
          <MediaBlock2
            views={0}
            viewsPerHour={0}
            publishedAt={currentHit.document.publishedAt}
            shortCode={currentHit.document.mediaShortCode}
            source={currentHit.document.mediaSource}
            type={currentHit.document.type}
            comments={media ? media.comments : 0}
            mediaViews={media ? media.views : 0}
            followers={media ? media.accountFollowers : 0}
            name={media ? media.accountName : ''}
            mediaName={currentHit.document.mediaName}
            accountId={media ? media.accountId : null}
            setDisplayText={() => {
              setDisplayText(!displayText);
            }}
          />
        </div>

        {/* <div className="col-span-1 flex flex-col justify-center items-center">
          <img
            alt="social media thumbnail"
            src={props.chunk.mediaThumbnail}
            width="30px"
          />
        </div> */}
        <div className="col-span-9">
          <div className="flex flex-col">
            <div className="text-base flex flex-row justify-start"></div>
            {displayText && currentHit ? (
              <div className="col-span-12 grid grid-cols-12">
                <div className="col-span-8">
                  <p
                    style={{ whiteSpace: 'break-spaces' }}
                    className="transcript m-0 mb-2"
                    dangerouslySetInnerHTML={{
                      __html: formattedTranscript,
                    }}
                  ></p>
                </div>
                <div className="col-span-4 pl-1 flex flex-col">
                  {props.chunk.hits.map((h) => {
                    return (
                      <span
                        key={h.document.id}
                        className="pl-2 cursor-pointer flex items-center font-bold underline text-gray-600 cyrsor-pointer"
                        onMouseEnter={() => {
                          console.log(
                            props.chunk.hits.find(
                              (h) => h.document.id === h.document.id
                            )
                          );
                          setOtherChunk(
                            props.chunk.hits.find(
                              (h) => h.document.id === h.document.id
                            )
                          );
                        }}
                      >
                        Match {h.document.n}
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : undefined}
            {commentsLoaded ? (
              <div className="grid grid-cols-12">
                <div className="col-span-8 flex items-center">
                  {Object.keys(comments).map((text) => {
                    return (
                      <p className="text-xs" key={text}>
                        {comments[text].count} comment
                        {comments[text].count === 0 ? 's mention' : ''}
                        {comments[text].count === 1 ? ' mentions' : ''}
                        {comments[text].count > 1 ? 's mention' : ''} "{text}"
                        &nbsp;
                        {comments[text].count > 0 ? (
                          <button
                            className="font-bold underline"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayComments(
                                displayComments === text ? undefined : text
                              );
                            }}
                          >
                            {displayComments
                              ? 'Hide comments'
                              : 'Display comments'}
                            {comments[text].count === 1 ? '' : 's'}
                          </button>
                        ) : undefined}
                      </p>
                    );
                  })}
                  {}
                </div>
                <div className="col-span-4"></div>

                {displayComments && comments[displayComments] ? (
                  <div className="col-span-8 flex items-start pt-2 flex flex-col">
                    {comments[displayComments].comments
                      .sort((a, b) => {
                        return a.publishedAt < b.publishedAt ? 1 : -1;
                      })
                      .map((c) => {
                        return (
                          <div
                            key={c.id}
                            className="flex flex-col items-start justify-start"
                          >
                            <CommentSmall key={c.id} comment={c} classNames="">
                              {c.mediaSource === 'youtube' ? (
                                <div className="flex items-center text-sm">
                                  {fullDate(new Date(c.publishedAt))}
                                  &nbsp;
                                  <LinkToYoutube
                                    text={`${c.youtubeName}`}
                                    n={0}
                                    mediaShortCode={c.mediaShortCode}
                                    additionalParams={`&lc=${c.commentId}`}
                                  ></LinkToYoutube>
                                </div>
                              ) : (
                                <></>
                              )}
                            </CommentSmall>
                          </div>
                        );
                      })}
                  </div>
                ) : undefined}
              </div>
            ) : (
              <div className=""></div>
            )}
          </div>
        </div>

        {/*currentHit.document.mediaSource === 'youtube' && (
          <div className="col-span-12 grid grid-cols-12 pb-8">
            <div className="col-span-3"></div>
            <div className="col-span-9">
              <NewCommentYoutube
                user={props.user}
                videoId={currentHit.document.mediaShortCode}
              ></NewCommentYoutube>
            </div>
          </div>
        )*/}
      </div>
    </div>
  );
};

export const LineChunkTs = connect(
  (state: RootState) => {
    return {
      user: getUser(state),
    };
  },
  (dispatch) => ({})
)(LineChunkTsComponent);
